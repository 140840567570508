import React, { useState } from 'react';
import { Table, Switch } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import styles from './index.module.scss';
import './index.scss';
import { totalDeductionData, totalDeductionRow } from 'constants/PayrollData';

type Props = {
	tableData: any;
	isLoading: boolean;
	showEmployer: boolean;
	setShowEmployer: any;

	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
};

const TotalDeductionTable: React.FC<Props> = (props: Props) => {
	const {
		tableData,
		isLoading,
		showEmployer,
		setShowEmployer,
		tableChangeHandler,
	} = props;

	const columns = [
		{
			title: '',
			className: 'background-color-dark',
			children: [
				{
					title: 'Employees',
					dataIndex: 'employee',
					key: 'employee',
					width: 250,
					fixed:"left",
					className: 'children-normal-font-weight',
					render: (text: string, record: any) => (
						<span>
							{record.id === 'total' ? text : `${text} | `}
							<span className="color-purple">
								{record.id === 'total'
									? ''
									: ` ${record.optionalEmployeeId}`}
							</span>
						</span>
					),
				},
			],
		},
		{
			title: (
				<div>
					Statutory components
					<Switch
						onChange={(checked) => setShowEmployer(checked)}
						style={{ marginLeft: 10 }}
					/>
					<span style={{ marginLeft: 10 }}>Show employers</span>
				</div>
			),
			className: 'background-color-dark',
			children: [
				{
					title: 'PAYE',
					dataIndex: 'paye',
					key: 'paye',
					width: '150px',
					className: 'children-normal-font-weight',
					render: (text: any) => <span>{text}</span>,
				},
				{
					title: 'NHIMA',
					className: 'children-normal-font-weight',

					children: [
						{
							title: 'Employees',
							dataIndex: 'nhimaEmployee',
							key: 'nhimaEmployee',
							width: '150px',
							className: 'children-normal-font-weight',
						},
						...(showEmployer
							? [
									{
										title: 'Employer',
										dataIndex: 'nhimaEmployer',
										key: 'nhimaEmployer',
										width: '150px',
										className:
											'children-normal-font-weight',
									},
							  ]
							: []),
					],
				},
				{
					title: 'NAPSA',
					className: 'children-normal-font-weight',

					children: [
						{
							title: 'Employees',
							dataIndex: 'napsaEmployee',
							key: 'napsaEmployee',
							width: '150px',
							className: 'children-normal-font-weight',
						},
						...(showEmployer
							? [
									{
										title: 'Employer',
										dataIndex: 'napsaEmployer',
										key: 'napsaEmployer',
										width: '150px',
										className:
											'children-normal-font-weight',
									},
							  ]
							: []),
					],
				},
			],
		},
		{
			title: 'Loans',
			className: 'background-color-dark',
			children: [
				{
					title: 'Mid month',
					dataIndex: 'midMonth',
					key: 'midMonth',
					width: '150px',
					className:
						'children-normal-font-weight border-right-none background-color-light',
				},
				{
					title: 'Cash loans',
					dataIndex: 'cashLoans',
					key: 'cashLoans',
					width: '150px',
					className: 'children-normal-font-weight border-right-none',
				},
				{
					title: 'House loan',
					dataIndex: 'houseLoan',
					key: 'houseLoan',
					width: '150px',
					className: 'children-normal-font-weight ',
				},
			],
		},
		{
			title: 'Funds and contributions',
			className: 'background-color-dark',
			children: [
				{
					title: 'Medical fund',
					dataIndex: 'medicalFund',
					key: 'medicalFund',
					width: '150px',
					className: 'children-normal-font-weight border-right-none',
				},
				{
					title: 'Union contribution',
					dataIndex: 'unionContribution',
					key: 'unionContribution',
					width: '150px',
					className: 'children-normal-font-weight',
				},
			],
		},
		{
			title: 'Other Deductions',
			className: 'background-color-dark',
			children: [
				{
					title: 'Meals/Sundry Deductions',
					dataIndex: 'mealsDeduction',
					key: 'mealsDeduction',
					className: 'children-normal-font-weight',
					width: '150px',
				},
			],
		},
		{
			title: 'Total',
			className: 'background-color-dark',
			children: [
				{ title: '', dataIndex: 'total', key: 'total', width: 150 },
			],
		},
	];

	return (
		<>
			<div className={styles['totalDeduction-dynamic-table']}>
				<Table
					columns={columns}
					dataSource={tableData}
					className="total-deduction-table"
					bordered
					scroll={{ x: 'max-content' }}
					pagination={false}
					onChange={tableChangeHandler}
					rowKey={(record: any) => record.id}
					loading={isLoading}
				/>
			</div>
		</>
	);
};

export default TotalDeductionTable;
