import PayeReportComponent from 'components/Report/PayeReport';

const PayeReport = () => {
	return (
		<>
			<PayeReportComponent />
		</>
	);
};

export default PayeReport;
