import dayjs from 'dayjs';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toastText } from 'utils/utils';
import NetPayTable from './Table';
import styles from './index.module.scss';
import { Col, Row } from 'antd';
import { NetPayImage } from 'utils/svgs';
import GlobalHeader from '../GlobalHeader';

type NetPayProps = {
	isLoading: boolean;
	netPayData: any;
	tableChangeHandler: (filter: any, sorter: any) => void;
};

const NetPayComponent = (props: NetPayProps) => {
	const { netPayData, isLoading, tableChangeHandler } = props;
	const navigate = useNavigate();
	return (
		<Row>
			<Col span={14}>
				<div className={styles['work-hours__table']}>
					<GlobalHeader />
					<NetPayTable
						netPayData={netPayData}
						isLoading={isLoading}
						tableChangeHandler={tableChangeHandler}
					/>
				</div>
			</Col>
			<Col span={1}></Col>
			<Col span={9}>
				<div>
					<NetPayImage />
				</div>
			</Col>
		</Row>
	);
};

export default NetPayComponent;
