import { getApi, postApi } from 'apis';

const getCompanyWizard = async () => {
	return await getApi('/payroll/payrollSettingWizard');
};

const getCompletedPayrollModules = async () => {
	return await getApi('/payroll/complete-payrollModules');
};

const getRemainingPayGroup = async (query: { payPeriodId: string }) => {
	return await getApi('/payroll/remaining-payGroup', query);
};

const getRemainingEmployeeList = async (query: { payPeriodId: string }) => {
	return await getApi('/payroll/remaining-employeeList', query);
};

const payGroupSetup = async () => {
	return await getApi('/payroll/payGroup-setup');
};

const getAllPayrolls = async (params: any) => {
	return await getApi('/payroll', params);
};

const getEmployeePaymentDetails = async (query : any) => {
	return await getApi('/employeePaymentDetails' , query)
}

const getPayslips = async (query : any) => {
	return await getApi('/employeePayslips' , query)
}



const savePayGroup = async (data: any) => {
	return await postApi('/payroll/payGroup-setup',data)
}

export const payrollApi = {
	getCompanyWizard,
	getRemainingPayGroup,
	getRemainingEmployeeList,
	payGroupSetup,
	getCompletedPayrollModules,
	getAllPayrolls,
	getEmployeePaymentDetails,
	getPayslips,
	savePayGroup,
};
