import { Table, Upload, Button } from 'antd';
import { EyeOutlined, UploadOutlined } from '@ant-design/icons';
import React from 'react';
import styles from './index.module.scss';
import Column from 'antd/es/table/Column';
import { DeleteActionSvg, EditActionSvg } from 'utils/svgs';

type TableProps = {
	tableData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	isLoading: boolean;
};

const PayrollHistoryTable = (props: TableProps) => {
	const {
		tableData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		isLoading,
	} = props;

	const uploadProps = {
		name: 'file',
		action: '/upload', // Replace with your API endpoint
		headers: {
			authorization: 'authorization-text',
		},
		onChange(info: any) {
			if (info.file.status === 'done') {
				console.log(`${info.file.name} file uploaded successfully`);
			} else if (info.file.status === 'error') {
				console.log(`${info.file.name} file upload failed.`);
			}
		},
	};

	const dataSource = [
		{
			id: 1,
			payPeriod: 'August 2024',
			approvedOn: '10/08/2024',
			paidOn: '15/08/2024',
			bankFile: 'BankFile123.pdf',
			transferReceipt: 'Receipt123.pdf',
			status: 'Payment done',
			action: 'View',
		},
	];

	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={dataSource} // Use dummy data here
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Pay Period"
					dataIndex="payPeriod"
					key="payPeriod"
					sorter={false}
					className="bg-white"
				/>
				<Column
					title="Approved On"
					dataIndex="approvedOn"
					key="approvedOn"
					sorter={false}
					className="bg-white"
				/>
				<Column
					title="Paid On"
					dataIndex="paidOn"
					key="paidOn"
					sorter={false}
					className="bg-white"
				/>
				<Column
					title="Bank file"
					dataIndex="bankFile"
					key="bankFile"
					sorter={false}
					className="bg-white"
					render={() => (
						<Upload {...uploadProps}>
							<Button
								icon={<UploadOutlined />}
								type="text"
								style={{ color: '#584495' }}
							>
								Upload file
							</Button>
						</Upload>
					)}
				/>
				<Column
					title="Transfer receipt"
					dataIndex="transferReceipt"
					key="transferReceipt"
					sorter={false}
					className="bg-white"
					render={() => (
						<Upload {...uploadProps}>
							<Button
								icon={<UploadOutlined />}
								type="text" // Removes border
								style={{ color: '#584495' }} // Changes text color to purple
							>
								Upload file
							</Button>
						</Upload>
					)}
				/>
				<Column
					title="Status"
					dataIndex="status"
					key="status"
					sorter={false}
					className="bg-white"
					render={(status) => (
						<span style={{ color: 'green' }}>{status}</span>
					)}
				/>
				<Column
					title="Action"
					dataIndex="action"
					key="action"
					sorter={false}
					className="bg-white"
					render={(_, record) => (
						<div>
							<Button
								type="text"
								icon={<EditActionSvg />}
								onClick={() => console.log('Edit', record)}
							/>
							<Button
								type="text"
								icon={
									<EyeOutlined
										style={{
											color: '#000',
											fontSize: '22px',
										}}
									/>
								}
								onClick={() => console.log('View', record)}
							/>
							<Button
								type="text"
								icon={<DeleteActionSvg />}
								onClick={() => console.log('Delete', record)}
							/>
						</div>
					)}
				/>
			</Table>
		</div>
	);
};

export default PayrollHistoryTable;
