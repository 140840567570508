import { useState } from 'react';
import { Checkbox, Table, Tooltip } from 'antd';
import styles from './index.module.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { employeeLeavesData } from 'constants/PayrollData';
import Column from 'antd/es/table/Column';
type Props = {
	isLoading: boolean;
	tableChangeHandler: (filter: any, sorter: any) => void;
	handleCheckboxChange: (id: string) => void;
	employeeData: any;
};

const EmployeeLeavesTable = (props: Props) => {
	const {
		isLoading,
		tableChangeHandler,
		handleCheckboxChange,
		employeeData,
	} = props;

	return (
		<>
			<div className={styles['employeeLeaves-dynamic-table']}>
				<Table
					dataSource={employeeData}
					pagination={false}
					scroll={{ y: 'calc(90vh - 360px)' }}
					onChange={tableChangeHandler}
					rowKey={(record) => record.id}
					loading={isLoading}
				>
					<Column
						title="Employee Name and Code"
						dataIndex="optionalEmployeeId"
						key="optionalEmployeeId"
						width={'30%'}
						className="bg-white"
						sorter={true}
						render={(text, record: any) => (
							<>
								<span>
									{record.firstName} {record.lastName}
								</span>{' '}
								|{' '}
								<span className="color-purple">
									{record.optionalEmployeeId}
								</span>
							</>
						)}
					/>

					<Column
						title="No. of Leaves"
						dataIndex="leaves"
						key="leaves"
						width={'20%'}
						className="bg-white"
						render={(text, record: any) => (
							<span>
								<a>{record.leaves}</a>
							</span>
						)}
					/>

					<Column
						title={
							<>
								Deducted from Salary{' '}
								<Tooltip title="Select this option if the leave taken by the employee should result in a deduction from their salary.">
									<ExclamationCircleOutlined />
								</Tooltip>
							</>
						}
						key="deductedFromSalary"
						width={'70%'}
						className="bg-white"
						render={(text, record: any) => (
							<>
								<Checkbox
									className={styles['checkbox']}
									checked={
										record.deductionType ===
										'deductedFromSalary'
									}
									onChange={() =>
										handleCheckboxChange(record.id)
									}
								/>{' '}
								{record.deductionType ===
									'deductedFromSalary' && (
									<span>
										<span style={{ color: '#584495' }}>
											{[record.leaves]}
										</span>{' '}
										leave days have been credited back to
										the employee's leave balance.
									</span>
								)}
							</>
						)}
					/>
				</Table>
			</div>
		</>
	);
};
export default EmployeeLeavesTable;
