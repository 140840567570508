import { PermissionContext } from 'components/Global/AuthLayout';
import TableActionHeader from 'components/Global/TableActionHeader';
import ReportLayout from 'layouts/Report';
import { useContext, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styles from './index.module.scss';

interface SelectedValue {
	key: string;
}

export default function Report() {
	const context = useContext(PermissionContext);
	const navigate = useNavigate();

	const pathname = window.location.pathname;
	const pathSegments = pathname.split('/');
	const lastSegment = pathSegments[pathSegments.length - 1];

	const [selectedSidebar, setSelectedSidebar] = useState<string>(lastSegment);

	// Function to handle sidebar changes
	const sideBarChangeHandler = (selectedValue: SelectedValue) => {
		setSelectedSidebar(selectedValue.key);
		navigate(selectedValue.key);
	};

	// useEffect(() => {
	// 	const allowedConfiguration = getAllowedModule(context.permissions, [
	// 		'Company',
	// 		'Branch Code',
	// 		'Category',
	// 		'Job Title',
	// 		'Pay Point',
	// 		'Departments',
	// 		'Cost Center',
	// 		'Job Grade',
	// 		// 'Earning',
	// 		// 'Deduction',
	// 		'Holidays',
	// 		'Leave Management',
	// 	]);
	// 	if (lastSegment === 'configuration') {
	// 		if (allowedConfiguration) {
	// 			const key =
	// 				keyWiseConfigurationRouteMapping[
	// 					allowedConfiguration.toLowerCase() as keyof typeof keyWiseConfigurationRouteMapping
	// 				];
	// 			navigate(`/settings/configuration/${key}`);
	// 		} else {
	// 			navigate(`/`);
	// 		}
	// 	}
	// 	setSelectedSidebar(lastSegment);
	// }, [lastSegment]);

	return (
		<>
			<TableActionHeader title={'report'} />
			<div className={styles['report']}>
				<ReportLayout
					onSideBarChange={sideBarChangeHandler}
					selectedSidebar={selectedSidebar}
				>
					<Outlet />
				</ReportLayout>
			</div>
		</>
	);
}
