import { SearchOutlined } from '@ant-design/icons';
import { Select, Space } from 'antd';
import SearchComponent from 'components/Global/SearchComponent';
import styles from './index.module.scss';
import Buttons from 'components/Global/Buttons';
import { useContext, useEffect, useState } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useDispatch, useSelector } from 'react-redux';
import { currencyData } from 'constants/CurrencyData';
import { AppDispatch } from 'redux/store';
import { fetchConstantDropdownAction } from '../../../../../redux/actions/constantDropdownAction';

type Props = {
	searchText: string;
	handleSearch: (value: string) => void;
	handleSearchApi: (value: string) => Promise<void>;
	dateValue: any;
	handleDatePicker: (value: any) => void;
	StatusFilter: string;
	handleStatusFilter: (value: string) => void;
	button: any;
};

const PayrollHeader = (props: Props) => {
	const permissionContext = useContext(PermissionContext);
	const {
		searchText,
		handleSearch,
		handleSearchApi,
		dateValue,
		handleDatePicker,
		StatusFilter,
		handleStatusFilter,
		button,
	} = props;
	const dispatch = useDispatch<AppDispatch>();
	const [selectedCurrency, setSelectedCurrency] = useState<any>();

	const constantDropdownOptions = useSelector(
		(state: any) => state.constantDropdown.data
	);

	const mappedCurrencyOptions = constantDropdownOptions?.currencyData?.map(
		(currency: any) => ({
			value: currency.value,
			label: `${currency.label} - ${
				currencyData[currency.label] || currency.label
			}`,
		})
	);

	useEffect(() => {
		dispatch(fetchConstantDropdownAction({}));
	}, [dispatch]);

	return (
		<div className={styles['payrollOverview-header']}>
			<Space>
				<SearchComponent
					className={styles['payrollOverview-header-item']}
					placeHolder="Search here..."
					suffixIcon={<SearchOutlined />}
					handleChange={handleSearch}
					handleChangeApi={handleSearchApi}
					value={searchText}
					size="large"
				/>
				<Select
					style={{ width: 150 }}
					className={styles['payrollOverview-header-item']}
					value={StatusFilter}
					options={[
						{ label: 'All', value: '' },
						{ label: 'Approved', value: 'APPROVED' },
						{
							label: 'Rejected',
							value: 'REJECTED',
						},
					]}
					onChange={(value) => handleStatusFilter(value)}
					size="large"
					placeholder="Select Status"
				/>
				<Select
					style={{ width: 150 }}
					className={styles['payrollOverview-header-item']}
					placeholder="Pay-period"
					//value={dateValue?.month}
					// options={monthOptions}
					// onChange={handleDatePicker}
					size="large"
				/>
				<Select
					style={{ width: 150 }}
					className={styles['payrollOverview-header-item']}
					// value={dateValue?.year}
					// options={yearOptions}
					// onSelect={handleDatePicker}
					 size="large"
					 placeholder="Select Year"
				/>
				<Select
					 placeholder="Select Currency"
					size="large"
					value={selectedCurrency}
					onSelect={(currency) => setSelectedCurrency(currency)}
					options={mappedCurrencyOptions}
				></Select>
			</Space>
			{<Buttons buttons={button} />}
		</div>
	);
};

export default PayrollHeader;
