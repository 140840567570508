import { Modal, Table, Tooltip } from 'antd';
import styles from './index.module.scss';
import { CloseOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import Column from 'antd/es/table/Column';
import Ellipse from 'components/Global/Ellipse';
import { EyeIcon } from 'utils/svgs';

type HistoryModalProps = {
	isModalOpen: boolean;
	handleCancel: () => void;
	historyLeaveData: any;
};

const data = [
	{
		key: '1',
		name: 'John Brown',
		description: 'New York No. 1 Lake Park',
		maxPaidLeavePerMonth: 12,
		leaveHistory: [
			{
				minMonth: 0,
				maxMonth: 6,
				leaves: 1,
			},
			{
				minMonth: 7,
				maxMonth: 12,
				leaves: 6,
			},
		],
	},
	{
		key: '2',
		name: 'Jim Green',
		description: 'London No. 1 Lake Park',
		maxPaidLeavePerMonth: 20,
		leaveHistory: [
			{
				minMonth: 0,
				maxMonth: 6,
				leaves: 5,
			},
			{
				minMonth: 7,
				maxMonth: 12,
				leaves: 10,
			},
		],
	},
];

const TooltipContent = ({ maxPaidLeavePerMonth, leaveHistory }: any) => {
	return (
		<div className={styles['tooltip-content']}>
			<div className={styles['tooltip-header']}>
				<span>Salary amount</span>
				<span>Percentage</span>
			</div>
			{/* {employeeShare.map((share: any, index: number) => (
				<div key={share.id} className={styles['tooltip-row']}>
					<span className={styles['tooltip-cell']}>
						{index === 0
							? `First ${share.maxAmount.toFixed(2)} ZMW`
							: index === employeeShare.length - 1
							? `${share.minAmount.toFixed(2)} ZMW - Onwards`
							: `Next ${share.minAmount.toFixed(
									2
							  )} ZMW - ${share.maxAmount.toFixed(2)} ZMW`}
					</span> */}
			{/* <span className={styles['tooltip-cell']}>
						{share.percentage.toFixed(2)}%
					</span> */}
		</div>
		// 	))}
		// </div>
	);
};

const HistoryPopOver = (data: any) => {
	const { maxPaidLeavePerMonth, leaveHistory } = data;
	return (
		<div className="ant-tooltip-container">
			<Tooltip
				title={
					<TooltipContent
						data={{
							maxPaidLeavePerMonth,
							leaveHistory,
						}}
					/>
				}
				color="#fff"
				placement="top"
				overlayInnerStyle={{ width: 'max-content' }}
				className="ant-tooltip-paye"
			>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<EyeIcon />
					<span>View Details</span>
				</div>
			</Tooltip>
		</div>
	);
};

const LeaveHistoryModal = (props: HistoryModalProps) => {
	const { isModalOpen, handleCancel, historyLeaveData } = props;
	return (
		<>
			<Modal
				open={isModalOpen}
				onCancel={handleCancel}
				footer={null}
				destroyOnClose={true}
				closable={false}
				width={800}
			>
				<div className={`${styles['modal']} modal`}>
					<div className={styles['modal-header']}>
						<Title level={4}>
							{' '}
							{historyLeaveData?.name} History
						</Title>
						<div
							className={styles['close-icon']}
							onClick={handleCancel}
						>
							<CloseOutlined />
						</div>
					</div>
					<Table dataSource={data} pagination={false}>
						<Column
							title="name"
							dataIndex="name"
							key="name"
							width={'20%'}
						/>
						<Column
							title="description"
							dataIndex="description"
							key="description"
							width={'20%'}
							render={(text) => (
								<Ellipse
									message={text}
									maxLength={20}
									key={text}
									tooltipMessage={text}
									isTooltip={true}
								/>
							)}
						/>
						<Column
							title="Leave History"
							dataIndex="leaveHistory"
							key="leaveHistory"
							width={'20%'}
							render={(text, data: any) => (
								<HistoryPopOver data={text} />
							)}
						/>
					</Table>
				</div>
			</Modal>
		</>
	);
};

export default LeaveHistoryModal;
