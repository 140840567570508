import { Table } from 'antd';
import { PermissionContext } from 'components/Global/AuthLayout';
import React, { useContext, useState } from 'react';
import styles from './index.module.scss';
import './index.scss';
import { FaFilePdf } from 'react-icons/fa';

const { Column } = Table;

type Props = {
	paySlipsData: any;
	isLoading: boolean;
	selectionType: any;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
};

const PaySlipTable: React.FC<Props> = (props: Props) => {
	const { paySlipsData, isLoading, selectionType, tableChangeHandler } =
		props;
	const rowSelection = {
		onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
			console.log(
				`selectedRowKeys: ${selectedRowKeys}`,
				'selectedRows: ',
				selectedRows
			);
		},
		getCheckboxProps: (record: any) => ({
			disabled: record.name === 'Disabled User',
		}),
	};
	return (
		<>
			<div className={styles['paySlip-dynamic-table']}>
				<Table
					dataSource={paySlipsData}
					className="pay-slips"
					scroll={{ y: 'calc(90vh - 360px)' }}
					pagination={false}
					onChange={tableChangeHandler}
					rowKey={(record) => record.id}
					loading={isLoading}
					rowSelection={{
						type: selectionType,
						...rowSelection,
					}}
				>
					<Column
						title="Employee Name and Code"
						dataIndex="optionalEmployeeId"
						key="optionalEmployeeId"
						width={'45%'}
						className="bg-white"
						render={(text, record: any) => (
							<>
								<span>
									{record.firstName} {record.lastName}
								</span>{' '}
								|{' '}
								<span className="color-purple">
									{record?.optionalEmployeeId}
								</span>
							</>
						)}
					/>

					<Column
						title="Pay Slips"
						width={'45%'}
						className="bg-white"
						dataIndex="paymentSlips"
						key="paymentSlips"
						render={(text, record: any) => (
							<>
								<FaFilePdf color="#d32f2f" size={20} />{' '}
								{record.paymentSlips}
							</>
						)}
					/>
				</Table>
			</div>
		</>
	);
};

export default PaySlipTable;
