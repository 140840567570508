import { Table } from 'antd';
import React from 'react';
import styles from './index.module.scss';
import './index.scss';

type Props = {
	totalEarningData: any;
	isLoading: boolean;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
};

const TotalEarningTable: React.FC<Props> = (props: Props) => {
	const { totalEarningData, isLoading, tableChangeHandler } = props;

	const columns = [
		{
			title: '',
			className: 'background-color-dark',

			children: [
				{
					title: 'Employees',
					dataIndex: 'employee',
					key: 'employee',
					fixed: 'left',
					width: 250,
					className: 'children-normal-font-weight',
					render: (text: string, record: any) => (
						<span>
							{record.id === 'total' ? text : `${text} | `}
							<span className="color-purple">
								{record.id === 'total' ? '' : ` ${record.id}`}
							</span>
						</span>
					),
				},
			],
		},

		{
			title: 'Allowance',
			className: 'background-color-dark',

			children: [
				{
					title: 'Housing Allowance ',
					dataIndex: 'housingAllowance',
					key: 'housingAllowance',
					className: 'children-normal-font-weight border-right-none',
					width: '150px',
				},
				{
					title: 'Transport Allowance ',
					dataIndex: 'transportAllowance',
					key: 'transportAllowance',
					className: 'children-normal-font-weight border-right-none',
					width: '150px',
				},
				{
					title: 'Gratuity Pay',
					dataIndex: 'gratuityPay',
					key: 'gratuityPay',
					className: 'children-normal-font-weight border-right-none',
					width: '150px',
				},
				{
					title: 'Shift Differential Pay',
					dataIndex: 'shiftDifferentialPay',
					key: 'shiftDifferentialPay',
					className: 'children-normal-font-weight border-right-none',
					width: '150px',
				},
				{
					title: 'House Rent',
					dataIndex: 'houseRent',
					key: 'houseRent',
					className: 'children-normal-font-weight ',
					width: '150px',
				},
			],
		},

		{
			title: 'Overtime',
			className: 'background-color-dark',

			children: [
				{
					title: 'Weekdays Overtime',
					dataIndex: 'weekDaysOvertime',
					key: 'weekDaysOvertime',
					className: 'children-normal-font-weight border-right-none',
					width: '150px',
				},
				{
					title: 'Sunday Overtime',
					dataIndex: 'sundayOvertime',
					key: 'sundayOvertime',
					className: 'children-normal-font-weight border-right-none',
					width: '150px',
				},
				{
					title: 'Lunch Allowance',
					dataIndex: 'lunchAllowance',
					key: 'lunchAllowance',
					className: 'children-normal-font-weight border-right-none',
					width: '150px',
				},
				{
					title: 'Seventh day Overtime',
					dataIndex: 'seventhDayOvertime',
					key: 'seventhDayOvertime',
					className: 'children-normal-font-weight',
					width: '150px',
				},
			],
		},

		{
			title: 'Leave Pay',
			className: 'background-color-dark',

			children: [
				{
					title: 'Leave Pay',
					dataIndex: 'leavePay',
					key: 'leavePay',
					className: 'children-normal-font-weight',
					width: '150px',
				},
			],
		},
		{
			title: 'Total',
			className: 'background-color-dark',

			children: [
				{ title: '', dataIndex: 'total', key: 'total', width: '150px' },
			],
		},
	];

	return (
		<>
			<div className={styles['totalEarning-table']}>
				<Table
					className="total-earning-table"
					columns={columns}
					dataSource={totalEarningData}
					bordered
					scroll={{ x: 'max-content' }}
					pagination={false}
					onChange={tableChangeHandler}
					rowKey={(record) => record.id}
					loading={isLoading}
				/>
			</div>
		</>
	);
};

export default TotalEarningTable;
