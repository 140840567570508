import NhimaReportComponent from 'components/Report/NhimaReport';

const NhimaReport = () => {
	return (
		<>
			<NhimaReportComponent />
		</>
	);
};

export default NhimaReport;
