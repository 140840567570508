import P18ReportComponent from 'components/Report/P18Report';
const P18Report = () => {
	return (
		<>
			<P18ReportComponent />
		</>
	);
};

export default P18Report;
