import PayrollSummaryReportComponent from 'components/Report/PayrollSummaryReport';
import React from 'react';

const PayrollSummaryReport = () => {
	return (
		<>
			<PayrollSummaryReportComponent />
		</>
	);
};

export default PayrollSummaryReport;
