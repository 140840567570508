import { CloseOutlined } from '@ant-design/icons';
import { Modal, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import Buttons from 'components/Global/Buttons';
import GlobalPayPeriod from 'components/Global/PayPeriod';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { invalidText, toastText } from 'utils/utils';
import styles from './index.module.scss';
import './index.scss';
import { reportApis } from 'Api/reports';

type GenerateP18ModalProps = {
	open: boolean;
	cancelGenerateModal: () => void;
	fetchReportDetails: () => void;
};
const GenerateP18Modal = (props: GenerateP18ModalProps) => {
	const { open, cancelGenerateModal, fetchReportDetails } = props;
	const [loading, setLoading] = useState(false);

	const [payPeriodError, setPayPeriodError] = useState<any>(false);

	const [selectedPayPeriod, setSelectedPayPeriod] = useState<string>('');
	const [selectedYear, setSelectedYear] = useState<any>(dayjs());

	const buttons = [
		{
			text: 'Generate',
			isLoading: loading,
			className: 'btn-blue',
			isSubmit: true,
			disabled: false,
			onclick: handleSubmit,
			minWidth: '10rem',
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: true,
			disabled: false,
			onclick: () => {
				handleCancelModal();
			},
			minWidth: '10rem',
		},
	];

	const handleCancelModal = () => {
		setSelectedPayPeriod('');
		setSelectedYear(dayjs());
		setPayPeriodError(false);
		cancelGenerateModal();
	};

	async function handleSubmit() {
		if (invalidText(selectedPayPeriod)) {
			setPayPeriodError(true);
			return;
		}

		try {
			setLoading(true);

			const data = {
				payPeriodId: selectedPayPeriod,
			};
			// await reportApis.generateP18Report(data);
			toastText('P18 report generated Successfully', 'success');
			fetchReportDetails();
			handleCancelModal();
		} catch (error: any) {
			console.error('error: ', error);
			if (error.response?.data?.error?.code !== 1005) {
				toastText(
					'Something went wrong generating P18 report',
					'error'
				);
			} else {
				toastText(error.response?.data?.message, 'error');
			}
		} finally {
			setLoading(false);
		}
	}

	const handleSelectYear = (value: any) => {
		setSelectedPayPeriod('');
		setSelectedYear(value);
	};

	const handlePayPeriodChange = (value: string) => {
		if (invalidText(value)) {
			setPayPeriodError(true);
			setSelectedPayPeriod(value);
			return;
		}

		setSelectedPayPeriod(value);
		setPayPeriodError(false);
	};

	useEffect(() => {
		if (open) {
			setSelectedPayPeriod('');
			setSelectedYear(dayjs());
		}
	}, [open]);

	return (
		<>
			<Modal
				open={open}
				width={400}
				maskClosable={true}
				closable={false}
				onCancel={handleCancelModal}
				footer={null}
				style={{ position: 'absolute', top: 200, right: 50 }}
			>
				<div className={`${styles['modal']} modal`}>
					<div className={styles['modal-header']}>
						<Title level={4}>Generate P18</Title>
						<div
							className={styles['close-icon']}
							onClick={handleCancelModal}
						>
							<CloseOutlined />
						</div>
					</div>
					<div>
						<Row
							className={styles['form-container-card']}
							gutter={50}
						>
							<GlobalPayPeriod
								handlePayPeriod={(value) =>
									handlePayPeriodChange(value)
								}
								selectedPayPeriod={selectedPayPeriod}
								isRow={true}
								handleSelectedYear={(value) =>
									handleSelectYear(value)
								}
								isYearRequired={true}
								isPayPeriodRequired={true}
								colSpan={24}
								colStyle={{ marginBottom: '1rem' }}
								payPeriodError={payPeriodError}
								selectedYear={selectedYear}
								rowStyle={{ margin: '0 0 1rem 0' }}
							/>
						</Row>

						<Row className={styles['modal-buttons']}>
							<Buttons buttons={buttons} />
						</Row>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default GenerateP18Modal;
