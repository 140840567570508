/* eslint-disable react-hooks/exhaustive-deps */
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import React, { useEffect, useState } from 'react';
import { toastText } from 'utils/utils';
import Styles from './index.module.scss';
import Buttons from 'components/Global/Buttons';
import GlobalReportTable from '../GlobalReportTable';
import GeneratePayrollSummaryModal from './GeneratePayrollSummaryModal';
import { reportApis } from 'Api/reports';

const PayrollSummaryReportComponent = () => {
	const [totalRecords, setTotalRecords] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [modalOpen, setModalOpen] = useState(false);
	const [payrollSummaryData, setPayrollSummaryData] = useState<any[]>([]);

	const buttons = [
		{
			text: 'Generate payroll summary',
			isLoading: false,
			className: 'btn-blue',
			// icon: <AddSvg />,
			isSubmit: true,
			onclick: () => {
				setModalOpen(true);
			},
			disabled: false,
		},
	];

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	const cancelModal = () => {
		setModalOpen(false);
	};

	const fetchPayrollSummaryReports = async () => {
		try {
			const query = {
				page: currentPage,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
			};
			setIsLoading(true);
			const payrollSummaryData =
				await reportApis.getPayrollSummaryReports(query);
			setPayrollSummaryData(payrollSummaryData.data.data);
			setTotalRecords(0);
		} catch (err: any) {
			let message =
				'Something went wrong in fetching payroll summary reports.';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchPayrollSummaryReports();
	}, [currentPage, sortField, sortOrder, pageSize]);

	return (
		<>
			<div className={Styles['container']}>
				<div className={Styles['payroll-summary-header']}>
					<p>Payroll summary</p>
					<Buttons buttons={buttons} />
				</div>
				<GlobalReportTable
					ReportData={payrollSummaryData}
					isLoading={isLoading}
					tableChangeHandler={tableChangeHandler}
					totalRecords={totalRecords}
					pageSize={pageSize}
					currentPage={currentPage}
				/>
			</div>
			{modalOpen && (
				<>
					<GeneratePayrollSummaryModal
						open={modalOpen}
						cancelGenerateModal={cancelModal}
						fetchPayrollSummaryDetails={fetchPayrollSummaryReports}
					/>
				</>
			)}
		</>
	);
};

export default PayrollSummaryReportComponent;
