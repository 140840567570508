import NapsaReportComponent from 'components/Report/NapsaReport';

const NapsaReport = () => {
	return (
		<>
			<NapsaReportComponent />
		</>
	);
};

export default NapsaReport;
