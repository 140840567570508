import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toastText } from 'utils/utils';
import TotalEarningTable from './Table';
import styles from './index.module.scss';
import GlobalHeader from '../GlobalHeader';

type TotalEarningProps = {
	isLoading: boolean;
	earningData: any;
	tableChangeHandler: (filter: any, sorter: any) => void;
};
const TotalEarningComponent = (props: TotalEarningProps) => {
	const { earningData, isLoading, tableChangeHandler } = props;
	const navigate = useNavigate();

	return (
		<div className={styles['total-earning__table']}>
			<GlobalHeader />
			<TotalEarningTable
				totalEarningData={earningData}
				isLoading={isLoading}
				tableChangeHandler={tableChangeHandler}
			/>
		</div>
	);
};

export default TotalEarningComponent;
