import { Header, Sidebar } from 'components/Global';
import { FORMDATA } from 'constants/Data';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import { PermissionContext } from 'components/Global/AuthLayout';
import { toastText } from 'utils/utils';
// import { RoleContext } from 'components/Global/AuthLayout';

const GlobalLayout = () => {
	// inits
	const { pageMenuItems } = FORMDATA;

	const { pathname } = useLocation();

	const [finalMenuItems, setMenuItems] = useState<any>(pageMenuItems);
	const [isLoading] = useState(false);

	const permissionContext = useContext(PermissionContext);

	const navigate = useNavigate();

	useEffect(() => {
		let menuItems = pageMenuItems;
		if (permissionContext.isSuperAdmin) {
			menuItems = pageMenuItems;
		} else {
			menuItems = pageMenuItems.map((item) => {
				if (item.key === 'time-activities-main') {
					const children = [];

					if (
						permissionContext.allowedPermissions.includes(
							'Allow_Time_Shift'
						)
					) {
						children.push({
							key: 'shifts',
							label: 'Time Shift',
						});
					}
					if (
						permissionContext.allowedPermissions.includes(
							'Allow_Time_Logs'
						)
					) {
						children.push({
							key: 'time-logs',
							label: 'Time Logs',
						});
					}
					if (
						permissionContext.allowedPermissions.includes(
							'Allow_Time_Activities'
						)
					) {
						children.push({
							key: 'time-activities',
							label: 'Time Activities',
						});
					}

					if (
						permissionContext.allowedPermissions.includes(
							'Allow_Time_Sheets'
						)
					) {
						children.push({
							key: 'time-sheets',
							label: 'Time Sheets',
						});
					}

					if (
						permissionContext.allowedPermissions.includes(
							'Allow_Leave_Request'
						)
					) {
						children.push({
							key: 'leave-request',
							label: 'Leave Request',
						});
					}

					return { ...item, children: children };
				} else if (item.key === 'run-payroll-main') {
					const children = [];
					if (
						permissionContext.allowedPermissions.includes(
							'Allow_Mid-Month_Pay'
						)
					) {
						children.push({
							key: 'mid-month',
							label: 'Mid Month Pay',
						});
					}
					if (
						permissionContext.allowedPermissions.includes(
							'Allow_Loan_Request'
						)
					) {
						children.push({
							key: 'loan-request',
							label: 'Loan Request',
						});
					}
					if (
						permissionContext.allowedPermissions.includes(
							'Allow_Statutory_Components'
						)
					) {
						children.push({
							key: 'calculate-statutory',
							label: 'Calculate Statutory',
						});
					}
					if (
						permissionContext.allowedPermissions.includes(
							'Allow_Payroll'
						)
					) {
						children.push({
							key: 'run-payroll',
							label: 'Run Payroll',
						});
						children.push({
							key: 'payroll-overview',
							label: 'Payroll Overview',
						});
					}
					return { ...item, children: children };
				}
				// else if (item.key === 'reports') {
				// 	const children = [];
				// 	// if (
				// 	// 	permissionContext.allowedPermissions.includes(
				// 	// 		'Allow_Calculate_Statutory'
				// 	// 	)
				// 	// ) {
				// 	children.push({
				// 		key: 'napsa',
				// 		label: 'NAPSA',
				// 	});
				// 	// }
				// 	// if (
				// 	// 	permissionContext.allowedPermissions.includes(
				// 	// 		'Allow_Calculate_Statutory'
				// 	// 	)
				// 	// ) {
				// 	children.push({
				// 		key: 'nhima',
				// 		label: 'NHIMA',
				// 	});
				// 	// }
				// 	// if (
				// 	// 	permissionContext.allowedPermissions.includes(
				// 	// 		'Allow_Calculate_Statutory'
				// 	// 	)
				// 	// ) {
				// 	children.push({
				// 		key: 'paye',
				// 		label: 'PAYE',
				// 	});
				// 	// }
				// 	// if (
				// 	// 	permissionContext.allowedPermissions.includes(
				// 	// 		'Allow_Calculate_Statutory'
				// 	// 	)
				// 	// ) {
				// 	children.push({
				// 		key: 'payroll-summary',
				// 		label: 'Payroll Summary',
				// 	});
				// 	// }
				// 	// if (
				// 	// 	permissionContext.allowedPermissions.includes(
				// 	// 		'Allow_Calculate_Statutory'
				// 	// 	)
				// 	// ) {
				// 	children.push({
				// 		key: 'p-18',
				// 		label: 'P-18',
				// 	});
				// 	// }
				// 	return { ...item, children: children };
				// }
				else {
					return item;
				}
			});
		}

		if (
			!permissionContext.allowedPermissions.includes('Allow_Employment')
		) {
			menuItems = menuItems.filter((item) => item.key !== 'employees');
		}

		const timeActivities = menuItems.find(
			(item) => item.key === 'time-activities-main'
		);
		if (timeActivities?.children?.length === 0) {
			menuItems = menuItems.filter(
				(item) => item.key !== 'time-activities-main'
			);
		}

		// const reportsMenu = menuItems.find((item) => item.key === 'reports');

		// if (reportsMenu?.children?.length === 0) {
		// 	menuItems = menuItems.filter((item) => item.key !== 'reports');
		// }

		const payrollMenu = menuItems.find(
			(item) => item.key === 'run-payroll-main'
		);
		if (payrollMenu?.children?.length === 0) {
			menuItems = menuItems.filter(
				(item) => item.key !== 'run-payroll-main'
			);
		}

		if (permissionContext.isSuperAdmin) {
			menuItems = menuItems.filter((item) => item.key === 'dashboard');
		}

		setMenuItems(menuItems);
	}, [pageMenuItems, permissionContext]);

	const [selectedSidebar, setSelectedSidebar] = useState<string>('dashboard');

	const handleSidebar = (sidebarProps: any) => {
		if (sidebarProps?.key === 'dashboard') {
			setSelectedSidebar('/');
			navigate(`/`);
		} else if (
			sidebarProps?.key === 'time-logs' ||
			sidebarProps?.key === 'time-activities' ||
			sidebarProps?.key === 'time-sheets' ||
			sidebarProps?.key === 'shifts' ||
			sidebarProps?.key === 'leave-request'
		) {
			setSelectedSidebar('time-activities-main');
			navigate(`/${sidebarProps?.key}`);
		} else if (
			sidebarProps?.key === 'mid-month' ||
			sidebarProps?.key === 'loan-request' ||
			sidebarProps?.key === 'calculate-statutory' ||
			sidebarProps?.key === 'payroll-overview'
		) {
			setSelectedSidebar('run-payroll-main');
			navigate(`/${sidebarProps?.key}`);
		}
		// else if (
		// 	sidebarProps?.key === 'napsa' ||
		// 	sidebarProps?.key === 'nhima' ||
		// 	sidebarProps?.key === 'paye' ||
		// 	sidebarProps?.key === 'payroll-summary' ||
		// 	sidebarProps?.key === 'p-18'
		// ) {
		// 	setSelectedSidebar('reports');
		// }
		else {
			setSelectedSidebar(sidebarProps?.key);
			navigate(`/${sidebarProps?.key}`);
		}
	};

	const initialFunctionCall = () => {
		if (pathname === '/') {
			setSelectedSidebar('dashboard');
		} else if (pathname === '/napsa') {
			// if (
			// 	permissionContext.allowedPermissions.includes(
			// 		'Allow_Calculate_Statutory'
			// 	)
			// ) {
			setSelectedSidebar('reports');
			// }
		} else if (pathname === '/nhima') {
			// if (
			// 	permissionContext.allowedPermissions.includes(
			// 		'Allow_Calculate_Statutory'
			// 	)
			// ) {
			setSelectedSidebar('reports');
			// }
		} else if (pathname === '/paye') {
			// if (
			// 	permissionContext.allowedPermissions.includes(
			// 		'Allow_Calculate_Statutory'
			// 	)
			// ) {
			setSelectedSidebar('reports');
			// }
		} else if (pathname === '/p-18') {
			// if (
			// 	permissionContext.allowedPermissions.includes(
			// 		'Allow_Calculate_Statutory'
			// 	)
			// ) {
			setSelectedSidebar('reports');
			// }
		} else if (pathname === '/payroll-summary') {
			// if (
			// 	permissionContext.allowedPermissions.includes(
			// 		'Allow_Calculate_Statutory'
			// 	)
			// ) {
			setSelectedSidebar('reports');
			// }
		} else if (pathname === '/employees') {
			if (
				permissionContext.allowedPermissions.includes(
					'Allow_Employment'
				)
			) {
				setSelectedSidebar('employees');
			} else {
				navigate('/');
			}
		} else if (pathname === '/time-logs') {
			if (
				permissionContext.allowedPermissions.includes('Allow_Time_Logs')
			) {
				setSelectedSidebar('time-activities-main');
			} else {
				navigate('/');
			}
		} else if (pathname === '/time-activities') {
			if (
				permissionContext.allowedPermissions.includes(
					'Allow_Time_Activities'
				)
			) {
				setSelectedSidebar('time-activities-main');
			} else {
				navigate('/');
			}
		} else if (pathname === '/time-sheets') {
			if (
				permissionContext.allowedPermissions.includes(
					'Allow_Time_Sheets'
				)
			) {
				setSelectedSidebar('time-activities-main');
			} else {
				navigate('/');
			}
		} else if (pathname === '/shifts') {
			if (
				permissionContext.allowedPermissions.includes(
					'Allow_Time_Shift'
				)
			) {
				setSelectedSidebar('time-activities-main');
			} else {
				navigate('/');
			}
		} else if (pathname === '/leave-request') {
			if (
				permissionContext.allowedPermissions.includes(
					'Allow_Loan_Request'
				)
			) {
				setSelectedSidebar('time-activities-main');
			} else {
				navigate('/');
			}
		} else if (pathname === '/loan-request') {
			if (
				permissionContext.allowedPermissions.includes(
					'Allow_Loan_Request'
				)
			) {
				setSelectedSidebar('payroll');
			} else {
				navigate('/');
			}
		} else if (pathname === '/payroll-overview') {
			if (
				permissionContext.allowedPermissions.includes('Allow_Payroll')
			) {
				setSelectedSidebar('run-payroll-main');
			} else {
				navigate('/');
			}
		} else if (pathname === '/mid-month') {
			if (
				permissionContext.allowedPermissions.includes(
					'Allow_Mid-Month_Pay'
				)
			) {
				setSelectedSidebar('run-payroll-main');
			} else {
				navigate('/');
			}
		} else if (pathname === '/calculate-statutory') {
			if (
				permissionContext.allowedPermissions.includes(
					'Allow_Statutory_Components'
				)
			) {
				setSelectedSidebar('run-payroll-main');
			} else {
				navigate('/');
			}
		}
	};

	useEffect(() => {
		initialFunctionCall();
	}, [pathname]);

	useEffect(() => {
		const token = localStorage.getItem('accessToken');
		if (!token) {
			navigate('/login');
			toastText('Your session has been expired, Login again!', 'error');
		}
	}, []);

	// JSX
	return !isLoading ? (
		<div className={styles['global-layout']}>
			<div className={styles['global-layout__wrapper']}>
				<div className={styles['global-layout__header']}>
					<Header />
				</div>
				<div className={styles['global-layout__body']}>
					<div className={styles['global-layout__body--sidebar']}>
						<Sidebar
							items={finalMenuItems}
							selectedSidebar={selectedSidebar}
							handleSidebar={handleSidebar}
						/>
					</div>
					<div className={styles['global-layout__body--body']}>
						<Outlet />
					</div>
				</div>
			</div>
		</div>
	) : null;
};

export default GlobalLayout;
