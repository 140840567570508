import { Table } from 'antd';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useContext } from 'react';
import styles from './index.module.scss';
import { workingHoursData } from 'constants/PayrollData';

const { Column } = Table;

type Props = {
	workHoursData: any;
	isLoading: boolean;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
};
const WorkingHoursTable = (props: Props) => {
	const { workHoursData, isLoading, tableChangeHandler } = props;

	const permissions = useContext(PermissionContext);
	return (
		<>
			<div className={styles['workHours-dynamic-table']}>
				<Table
					dataSource={workHoursData}
					scroll={{ y: 'calc(90vh - 360px)' }}
					// pagination={{
					// 	total: totalRecords,
					// 	current: currentPage,
					// 	pageSize: pageSize,
					// 	showSizeChanger: false,
					// 	// onShowSizeChange: pageSizeHandler,
					// 	pageSizeOptions: [10, 20, 50, 100, 200],
					// }}
					pagination={false}
					onChange={tableChangeHandler}
					rowKey={(record) => record.id}
					loading={isLoading}
				>
					<Column
						title="Employee Name and Code"
						dataIndex="optionalEmployeeId"
						key="optionalEmployeeId"
						width={'20%'}
						className="bg-white"
						sorter={true}
						render={(text, record: any) => (
							<>
								<span>
									{record.firstName} {record.lastName}
								</span>{' '}
								|{' '}
								<span className="color-purple">
									{record.employeeCode}
								</span>
							</>
						)}
					/>

					<Column
						title="Regular Hours"
						dataIndex="regularHours"
						key="regularHours"
						width={'10%'}
						className="bg-white"
						sorter={true}
					/>
					<Column
						title="Weekday OT"
						dataIndex="weekdaysOT"
						key="weekdaysOT"
						width={'10%'}
						className="bg-white"
						sorter={true}
					/>
					<Column
						title="Sunday OT"
						dataIndex="sundayOT"
						key="sundayOT"
						width={'10%'}
						className="bg-white"
						sorter={true}
					/>
					<Column
						title="Shift Difference"
						dataIndex="timeShiftHours"
						key="timeShiftHours"
						width={'10%'}
						className="bg-white"
						sorter={true}
					/>
					<Column
						title="Hourly rate"
						dataIndex="hourlySalary"
						key="hourlySalary"
						width={'10%'}
						className="bg-white"
						sorter={true}
						render={(text, record: any) => (
							<>{record.hourlySalary} </>
						)}
					/>
					<Column
						title="Total Hours"
						key="totalHours"
						dataIndex="totalHours"
						width={'10%'}
						className="bg-white"
						sorter={true}
					/>
				</Table>
			</div>
		</>
	);
};
export default WorkingHoursTable;
