import { Divider, Steps, Button } from 'antd';
import { payrollApi } from 'Api/payroll';
import Buttons from 'components/Global/Buttons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CloseSvg, ToggleCloseButton, ToggleOpenButton } from 'utils/svgs';
import { hasTrueValue, invalidText, toastText } from 'utils/utils';
import EmployeeLeaveComponent from './EmployeeLeaves';
import styles from './index.module.scss';
import './index.scss';
import NetPayComponent from './NetPay';
import PayGroupSetupComponent from './PayGroup';
import PaySlipComponent from './PaySlips';
import TotalDeductionComponent from './TotalDeduction';
import TotalEarningComponent from './TotalEarning';
import WorkHourComponent from './WorkHours';
import dayjs from 'dayjs';
import { TableSorter } from 'interfaces/global.interface';
import {
	employeeLeavesData,
	paySlipData,
	totalDeductionData,
	totalDeductionRow,
	totalEarningData,
	totalEarningRow,
	totalPayData,
	workingHoursData,
} from 'constants/PayrollData';

const { Step } = Steps;

const RunPayrollComponent = () => {
	const navigate = useNavigate();
	const [current, setCurrent] = useState(0);

	const [showSteps, setShowSteps] = useState(true);

	// Loading
	const [isSaveLoading, setIsSaveLoading] = useState(false);

	// Employee Leaves
	const [employeeData, setEmployeeData] = useState<any[]>(employeeLeavesData);
	const [employeeLeaveLoading, setEmployeeLeaveLoading] = useState(false);
	const [employeeLeaveSortField, setEmployeeLeavesortField] = useState('');
	const [employeeLeaveSortOrder, setEmployeeLeaveSortOrder] = useState('');

	// Working Hours
	const [workHoursData, setWorkHoursData] = useState<any[]>(workingHoursData);
	const [workHoursLoading, setWorkHoursLoading] = useState(false);
	const [workHourSortField, setWorkHourSortField] = useState('');
	const [workHourSortOrder, setWorkHourSortOrder] = useState('');

	// Total Earning

	const [earningData, setEarningData] = useState<any[]>(totalEarningData);
	const [totalEarningLoading, setTotalEarningLoading] = useState(false);
	const [totalEarningSortField, setTotalEarningSortField] = useState('');
	const [totalEarningSortOrder, setTotalEarningSortOrder] = useState('');

	// Total Deduction

	const [showEmployer, setShowEmployer] = useState(false);
	const [deductionData, setDeductionData] =
		useState<any[]>(totalDeductionData);
	const [totalDeductionLoading, setTotalDeductionLoading] = useState(false);
	const [totalDeductionSortField, setTotalDeductionSortField] = useState('');
	const [totalDeductionSortOrder, setTotalDeductionSortOrder] = useState('');

	// Pay slips

	const [paySlipsData, setPaySlipsData] = useState<any[]>(paySlipData);
	const [paySlipsLoading, setPaySlipsLoading] = useState(false);
	const [paySlipsSortField, setPaySlipsSortField] = useState('');
	const [paySlipsSortOrder, setPaySlipsSortOrder] = useState('');
	const [selectionType, setSelectionType] = useState();

	// Net Pay

	const [netPayData, setNetPayData] = useState<any[]>(totalPayData);
	const [netPayLoading, setNetPayLoading] = useState(false);
	const [netPaySortField, setNetPaySortField] = useState('');
	const [netPaySortOrder, setNetPaySortOrder] = useState('');

	// Pay Period

	const [selectedYear, setSelectedYear] = useState<any>(dayjs());
	const [selectedPayPeriod, setSelectedPayPeriod] = useState('');

	// Pay Group

	const [selectedPayGroup, setSelectedPayGroup] = useState([]);
	const [payGroupOptions, setPayGroupOptions] = useState([]);
	const [selectedEmployees, setSelectedEmployees] = useState<any[]>([]);
	const [payGroupError, setPayGroupError] = useState({
		year: false,
		payPeriod: false,
		payGroup: false,
	});

	const [remainingEmployees, setRemainingEmployees] = useState<any[]>([]);

	// Fetch remaining pay-groups
	const fetchRemainingPayGroups = async (selectedPayPeriod: string) => {
		try {
			const response = await payrollApi.getRemainingPayGroup({
				payPeriodId: selectedPayPeriod,
			});

			const data = response.data.data.map((item: any) => {
				return {
					...item,
					label: item.name,
					value: item.id,
				};
			});

			setPayGroupOptions(data);
		} catch (err) {
			toastText('Something went wrong in fetching pay-groups.', 'error');
		}
	};

	// Fetch remaining pay-groups
	const fetchRemainingEmployees = async (selectedPayPeriod: string) => {
		try {
			const response = await payrollApi.getRemainingEmployeeList({
				payPeriodId: selectedPayPeriod,
			});

			const options = response.data?.data
				? response.data?.data?.map((item: any) => {
						return {
							value: item.id,
							label: `${item.firstName} ${item.lastName}`,
							employeeId: item.employeeId,
							isAdded: false,
						};
				  })
				: [];

			setRemainingEmployees(options);
		} catch (err) {
			toastText(
				'Something went wrong in fetching remaining employees.',
				'error'
			);
		}
	};

	// Select employee
	const addEmployee = async (employeeId: string) => {
		const findEmployee = remainingEmployees.find(
			(emp: any) => emp.value === employeeId
		);
		setSelectedEmployees((prev: any) => [...prev, findEmployee]);

		const employees = remainingEmployees.map((emp: any) => {
			if (emp.value === employeeId) {
				return {
					...emp,
					isAdded: true,
				};
			} else {
				return {
					...emp,
				};
			}
		});
		setRemainingEmployees(employees);
	};

	// Remove employee
	const removeEmployee = async (employeeId: string) => {
		setSelectedEmployees((prev: any) => {
			return prev.filter((item: any) => item !== employeeId);
		});

		const employees = remainingEmployees.map((emp: any) => {
			if (emp.value === employeeId) {
				return {
					...emp,
					isAdded: false,
				};
			} else {
				return {
					...emp,
				};
			}
		});
		setRemainingEmployees(employees);
	};

	// Handle Pay Period
	const handlePayPeriod = (value: string) => {
		setSelectedPayPeriod(value);
		if (invalidText(value)) {
			setSelectedEmployees([]);
			setRemainingEmployees([]);
			setPayGroupError((prev) => {
				return {
					...prev,
					payPeriod: true,
				};
			});
		} else {
			setPayGroupError((prev) => {
				return {
					...prev,
					payPeriod: false,
				};
			});
		}
	};

	// Fetch payrol by payperiod 

	// Handle Pay Group
	const handlePayGroup = (value: any) => {
		setSelectedPayGroup(value);
		if (invalidText(value)) {
			setSelectedEmployees([]);
			setRemainingEmployees([]);
			setPayGroupError((prev) => {
				return {
					...prev,
					payGroup: true,
				};
			});
		} else {
			setPayGroupError((prev) => {
				return {
					...prev,
					payGroup: false,
				};
			});
		}
	};

	// Save pay group setup information
	const savePayGroup = async () => {
		try {
			setIsSaveLoading(true);
			const payGroup = await payrollApi.savePayGroup({
				payPeriodId: selectedPayPeriod,
				payGroupIds: selectedPayGroup,
				payYear: dayjs(selectedYear).year(),
				employeeIds: selectedEmployees.map((item) => item.value),
			});
			console.log('payGroup: ', payGroup);

			toastText('Pay-Group setup completed successfully.', 'success');
		} catch (err: any) {
			let message = 'Something went wrong in saving pay group.';

			if (err.response.data.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsSaveLoading(false);
		}
	};

	const handleSubmit = () => {
		console.log('handle Submit', steps);
	};

	const savePayroll = () => {
		// If step 0 then save pay group
		if (current === 0) {
			if (invalidText(selectedPayPeriod)) {
				setPayGroupError((prev) => {
					return {
						...prev,
						payPeriod: true,
					};
				});
			}
			if (selectedPayGroup.length === 0) {
				setPayGroupError((prev) => {
					return {
						...prev,
						payGroup: true,
					};
				});
			}

			if (
				invalidText(selectedPayPeriod) ||
				selectedPayGroup.length === 0
			) {
				return;
			} else {
				savePayGroup();
			}
		}
	};

	useEffect(() => {
		if (selectedPayPeriod) {
			fetchRemainingPayGroups(selectedPayPeriod);
			fetchRemainingEmployees(selectedPayPeriod);
		}
	}, [selectedPayPeriod]);

	useEffect(() => {
		if (selectedPayGroup && payGroupOptions.length > 0) {
			let employees: any = [];

			for (const i of selectedPayGroup) {
				const payGroup: any = payGroupOptions.find(
					(payGroup: any) => payGroup.id === i
				);
				if (payGroup) {
					employees = [...employees, ...payGroup.PayGroupEmployee];
				}
			}
		}
	}, [selectedPayGroup, payGroupOptions]);

	useEffect(()=>{


		
	},[selectedPayGroup,selectedPayPeriod])

	// Employee Leaves

	const handleCheckboxChange = (id: string) => {
		const updatedData = employeeData.map((item) => {
			if (item.id === id) {
				return {
					...item,
					deductionType:
						item.deductionType === 'deductedFromSalary'
							? 'deductedFromLeaveBalance'
							: 'deductedFromSalary',
				};
			}
			return item;
		});
		setEmployeeData(updatedData);
	};

	const employeeTableChangeHandler = (filters: any, sorter: TableSorter) => {
		setEmployeeLeaveSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setEmployeeLeavesortField(sorter.field);
	};

	const fetchEmployeeLeave = async () => {
		try {
			const query = {
				sortBy: employeeLeaveSortField,
				sortOrder: employeeLeaveSortOrder,
			};
			setEmployeeLeaveLoading(true);
			// const leaveRequest = await leaveRequestApi.getLeaveRequests(query);
			// setLeaveRequestData(leaveRequest.data.data);
			// setTotalRecords(leaveRequest.data.total);
		} catch (err: any) {
			let message = 'Something went wrong in fetching leave request.';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setEmployeeLeaveLoading(false);
		}
	};

	useEffect(() => {
		fetchEmployeeLeave();
	}, [employeeLeaveSortField, employeeLeaveSortOrder]);

	// Working Hours
	const workingHourstableChangeHandler = (
		filters: any,
		sorter: TableSorter
	) => {
		setWorkHourSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setWorkHourSortField(sorter.field);
	};

	const fetchWorkHours = async () => {
		try {
			const query = {
				sortBy: workHourSortField,
				sortOrder: workHourSortOrder,
			};
			setWorkHoursLoading(true);
			// const workHours = await workHoursApi.getWorkHours(query);
			// setWorkHoursData(workHours.data.data);
			// setTotalRecords(workHours.data.total);
		} catch (err: any) {
			let message = 'Something went wrong in fetching work hours.';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setWorkHoursLoading(false);
		}
	};

	useEffect(() => {
		fetchWorkHours();
	}, [setWorkHourSortField, setWorkHourSortOrder]);

	//Total Earning

	const totalEarningTableChangeHandler = (
		filters: any,
		sorter: TableSorter
	) => {
		setTotalEarningSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setTotalEarningSortField(sorter.field);
	};

	const totalRow = totalEarningRow;

	const dataWithTotal: any = [...totalEarningData, totalRow];

	const fetchTotalEarning = async () => {
		try {
			const query = {
				sortBy: totalEarningSortField,
				sortOrder: totalEarningSortOrder,
			};
			setTotalEarningLoading(true);
			// const leaveRequest = await leaveRequestApi.getLeaveRequests(query);
			// setLeaveRequestData(leaveRequest.data.data);
			// setTotalRecords(leaveRequest.data.total);
		} catch (err: any) {
			let message = 'Something went wrong in fetching leave request.';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setTotalEarningLoading(false);
		}
	};

	useEffect(() => {
		fetchTotalEarning();
	}, [totalEarningSortField, totalEarningSortOrder]);

	//Total Deduction

	const totalDeductionTableChangeHandler = (
		filters: any,
		sorter: TableSorter
	) => {
		setTotalDeductionSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setTotalDeductionSortField(sorter.field);
	};

	const totalRowDeduction = totalDeductionRow(showEmployer);

	const deductionDataWithTotal: any = [
		...totalDeductionData,
		totalRowDeduction,
	];

	const fetchTotalDeduction = async () => {
		try {
			const query = {
				sortBy: totalDeductionSortField,
				sortOrder: totalDeductionSortOrder,
			};
			setTotalDeductionLoading(true);
			// const leaveRequest = await leaveRequestApi.getLeaveRequests(query);
			// setLeaveRequestData(leaveRequest.data.data);
			// setTotalRecords(leaveRequest.data.total);
		} catch (err: any) {
			let message = 'Something went wrong in fetching leave request.';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setTotalDeductionLoading(false);
		}
	};

	useEffect(() => {
		fetchTotalDeduction();
	}, [totalDeductionSortField, totalDeductionSortOrder]);

	//Pay slips

	const paySlipsTableChangeHandler = (filters: any, sorter: TableSorter) => {
		setPaySlipsSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setPaySlipsSortField(sorter.field);
	};

	const fetchPaySlips = async () => {
		try {
			const query = {
				sortBy: paySlipsSortField,
				sortOrder: paySlipsSortOrder,
			};
			setPaySlipsLoading(true);
			// const leaveRequest = await leaveRequestApi.getLeaveRequests(query);
			// setLeaveRequestData(leaveRequest.data.data);
			// setTotalRecords(leaveRequest.data.total);
		} catch (err: any) {
			let message = 'Something went wrong in fetching leave request.';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setPaySlipsLoading(false);
		}
	};

	useEffect(() => {
		fetchPaySlips();
	}, [paySlipsSortField, paySlipsSortOrder]);

	//Net Pay
	const netPayTableChangeHandler = (filters: any, sorter: TableSorter) => {
		setNetPaySortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setNetPaySortField(sorter.field);
	};

	const fetchNetPay = async () => {
		try {
			const query = {
				sortBy: netPaySortField,
				sortOrder: netPaySortOrder,
			};
			setNetPayLoading(true);
			// const leaveRequest = await leaveRequestApi.getLeaveRequests(query);
			// setLeaveRequestData(leaveRequest.data.data);
			// setTotalRecords(leaveRequest.data.total);
		} catch (err: any) {
			let message = 'Something went wrong in fetching leave request.';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setNetPayLoading(false);
		}
	};

	useEffect(() => {
		fetchNetPay();
	}, [netPaySortField, netPaySortOrder]);

	const steps = [
		{
			title: '1. Select pay group',
			content: (
				<PayGroupSetupComponent
					selectedPayGroup={selectedPayGroup}
					handlePayGroup={(value) => handlePayGroup(value)}
					selectedYear={selectedYear}
					handleSelectedYear={(value) => setSelectedYear(value)}
					selectedPayPeriod={selectedPayPeriod}
					handlePayPeriod={(value) => handlePayPeriod(value)}
					payGroupOptions={payGroupOptions}
					remainingEmployees={remainingEmployees}
					addEmployee={addEmployee}
					removeEmployee={removeEmployee}
					payGroupError={payGroupError}
				/>
			),
		},
		{
			title: '2. Working hours',
			content: (
				<WorkHourComponent
					tableChangeHandler={workingHourstableChangeHandler}
					workHoursData={workHoursData}
					isLoading={workHoursLoading}
				/>
			),
		},
		{
			title: "3. Employee's leave",
			content: (
				<EmployeeLeaveComponent
					tableChangeHandler={employeeTableChangeHandler}
					handleCheckboxChange={handleCheckboxChange}
					employeeData={employeeData}
					isLoading={employeeLeaveLoading}
				/>
			),
		},
		{
			title: '4. Total deductions',
			content: (
				<TotalDeductionComponent
					tableChangeHandler={totalDeductionTableChangeHandler}
					isLoading={totalDeductionLoading}
					deductionData={deductionDataWithTotal}
					showEmployer={showEmployer}
					setShowEmployer={setShowEmployer}
				/>
			),
		},
		{
			title: '5. Total earnings',
			content: (
				<TotalEarningComponent
					earningData={dataWithTotal}
					isLoading={totalEarningLoading}
					tableChangeHandler={totalEarningTableChangeHandler}
				/>
			),
		},
		{
			title: '6. Net pay',
			content: (
				<NetPayComponent
					tableChangeHandler={netPayTableChangeHandler}
					isLoading={netPayLoading}
					netPayData={netPayData}
				/>
			),
		},
		{
			title: '7. Pay Slips',
			content: (
				<PaySlipComponent
					tableChangeHandler={paySlipsTableChangeHandler}
					isLoading={paySlipsLoading}
					paySlipsData={paySlipsData}
					selectionType={selectionType}
				/>
			),
		},
	];

	const buttons = [
		{
			text: 'Back',
			isLoading: false,
			className: 'secondary-button',
			minWidth: '12rem',
			disabled: current === 0 || isSaveLoading,
			minHeight: '4rem',
			isSubmit: false,
			onclick: () => prev(),
		},
		{
			text: 'Save as Draft',
			className: 'btn-blue',
			minWidth: '12rem',
			disabled: isSaveLoading,
			isLoading: isSaveLoading,
			minHeight: '4rem',
			isSubmit: false,
			onclick: () => {
				savePayroll();
			},
		},
		{
			text:
				current === steps.length - 1
					? 'Send for Approval'
					: 'Save & Next',
			className: 'btn-blue',
			minWidth: '12rem',
			isLoading: false,
			minHeight: '4rem',
			disabled: isSaveLoading,
			isSubmit: false,
			onclick: () => {
				if (current === steps.length - 1) {
					handleSubmit();
				} else {
					next();
				}
			},
		},
	];

	const handleStepChange = (newStep: number) => {
		if (newStep <= current) {
			setCurrent(newStep);
		}
	};
	// const next = () => setCurrent(current + 1);
	const next = () => {
		switch (current) {
			case 0:
				console.log(
					'Step 1 Data - Selected Pay Group:',
					selectedPayGroup
				);
				console.log('Selected Pay Period:', selectedPayPeriod);
				console.log('Selected Year:', selectedYear);
				break;
			case 1:
				console.log('Step 2 Data - Working Hours:', workHoursData);
				break;
			case 2:
				console.log('Step 3 Data - Employee Leaves:', employeeData);
				break;
			case 3:
				console.log(
					'Step 4 Data - Total Deductions:',
					deductionDataWithTotal
				);
				break;
			case 4:
				console.log('Step 5 Data - Total Earnings:', dataWithTotal);
				break;
			case 5:
				console.log('Step 6 Data - Net Pay:', netPayData);
				break;
			case 6:
				console.log('Step 7 Data - Pay Slips:', paySlipsData);
				break;
			default:
				console.log('Unknown step');
				break;
		}

		setCurrent((prevCurrent) => prevCurrent + 1);
	};

	const prev = () => setCurrent(current - 1);

	return (
		<>
			<div className="modal-animation">
				<div className={styles['run-payroll']}>
					<div className={styles['run-payroll__header']}>
						<div className={styles['run-payroll__header__title']}>
							Run Payroll
						</div>
						<div
							className={styles['run-payroll__header__actions']}
							onClick={() => {
								navigate('/payroll-overview');
							}}
						>
							<CloseSvg />
						</div>
					</div>
					<div className={styles['run-payroll__table']}>
						{showSteps && (
							<div className="step-container">
								<div className="steps-header">
									<Steps
										current={current}
										// onChange={setCurrent}
										className="custom-steps"
										size="small"
										labelPlacement="vertical"
										onChange={handleStepChange}
									>
										{steps.map((item, index) => (
											<Step
												key={index}
												title={
													<>
														{item.title}
														{current > index && (
															<div className="completed-text">
																Completed
															</div>
														)}
													</>
												}
											/>
										))}
									</Steps>
								</div>
							</div>
						)}
						<Divider className="steps-divider" />
						<Button
							className="show-steps"
							onClick={() => setShowSteps(!showSteps)}
							icon={
								showSteps ? (
									<ToggleOpenButton />
								) : (
									<ToggleCloseButton />
								)
							}
						></Button>
						<div className="steps-content">
							{steps[current].content}
						</div>
						<div className="buttons-container">
							<Buttons
								buttons={buttons.filter(
									(button) =>
										!(
											button.text === 'Back' &&
											current === 0
										)
								)}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default RunPayrollComponent;
