import dayjs from 'dayjs';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toastText } from 'utils/utils';
import styles from './index.module.scss';
import PaySlipTable from './Table';
import { PaySlipsImage } from 'utils/svgs';
import { Col, Row } from 'antd';
import GlobalHeader from '../GlobalHeader';

type PaySlipProps = {
	isLoading: boolean;
	paySlipsData: any;
	selectionType: any;
	tableChangeHandler: (filter: any, sorter: any) => void;
};
const PaySlipComponent = (props: PaySlipProps) => {
	const { paySlipsData, isLoading, tableChangeHandler, selectionType } =
		props;
	const navigate = useNavigate();

	return (
		<>
			<Row>
				<Col span={24}>
					<GlobalHeader Payslip={true} />
				</Col>
			</Row>
			<Row>
				<Col span={14}>
					<div className={styles['pay-Slip__table']}>
						<PaySlipTable
							paySlipsData={paySlipsData}
							isLoading={isLoading}
							tableChangeHandler={tableChangeHandler}
							selectionType={selectionType}
						/>
					</div>
				</Col>
				<Col span={3}></Col>
				<Col span={7}>
					<div>
						<PaySlipsImage />
					</div>
				</Col>
			</Row>
		</>
	);
};

export default PaySlipComponent;
