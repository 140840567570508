import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toastText } from 'utils/utils';
import TotalDeductionTable from './Table';
import styles from './index.module.scss';
import GlobalHeader from '../GlobalHeader';

type totalDeductionProps = {
	isLoading: boolean;
	tableChangeHandler: (filter: any, sorter: any) => void;
	deductionData: any;
	showEmployer: boolean;
	setShowEmployer: any;
};
const TotalDeductionComponent = (props: totalDeductionProps) => {
	const {
		isLoading,
		tableChangeHandler,
		setShowEmployer,
		deductionData,
		showEmployer,
	} = props;
	const navigate = useNavigate();

	return (
		<div className={styles['total-deduction__table']}>
			<GlobalHeader />
			<TotalDeductionTable
				tableData={deductionData}
				isLoading={isLoading}
				tableChangeHandler={tableChangeHandler}
				showEmployer={showEmployer}
				setShowEmployer={setShowEmployer}
			/>
		</div>
	);
};

export default TotalDeductionComponent;
