import { companySetup } from 'Api/companySetup';
import { directDepositApi } from 'Api/directDeposit';
import { Col, Form, Row } from 'antd';
import Buttons from 'components/Global/Buttons';
import InputField from 'components/Global/InputField';
import InputNumberField from 'components/Global/InputNumberField';
import SelectDropdown from 'components/Global/SelectDropdown';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { AppDispatch } from 'redux/store';
import {
	hasFormError,
	invalidText,
	toastText,
	validateFormData,
} from 'utils/utils';
import styles from './index.module.scss';
type DirectDepositProps = {
	onCancel: () => void;

	trueVerifiedAccount: () => void;
	falseVerifiedAccount: () => void;
};

const DirectDeposit = ({
	onCancel,
	falseVerifiedAccount,
	trueVerifiedAccount,
}: DirectDepositProps) => {
	const [form] = Form.useForm();
	const [isLoading, setIsLoading] = useState(false);
	const [isVerifyLoading, setIsVerifyLoading] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const [directDepositeDetails, setDirectDepositeDetails] = useState<any>({
		accountHolderName: null,
		bankId: null,
		accountNumber: null,
		reAccountNumber: null,
		sortCode: null,
		isVerified: false,
	});
	const [formError, setFormError] = useState<any>({
		accountHolderName: false,
		accountNumber: false,
		bankId: false,
		reAccountNumber: false,
		sortCode: false,
	});
	const [hasError, setHasError] = useState(false);

	const dispatch = useDispatch<AppDispatch>();
	const constantDropdownOptions = useSelector(
		(state: any) => state.constantDropdown.data
	);
	const handleChange = (
		value: string | number | null,
		name: string,
		required: boolean,
		regex?: RegExp | null
	) => {
		if (required) {
			setHasError(invalidText(value));
		}

		if (typeof value === 'number') {
			if (value <= 0) {
				setHasError(true);
				return;
			}
		}

		if (typeof value === 'string' && regex) {
			const _regex = new RegExp(regex);
			setHasError(!_regex.test(value));
		}

		OnChange(value, name);
		if (name === 'accountNumber' || name === 'reAccountNumber') {
			const { accountNumber, reAccountNumber } = directDepositeDetails;
			const isMatch =
				value ===
				(name === 'accountNumber' ? reAccountNumber : accountNumber);
			setFormError((prev: any) => ({
				...prev,
				reAccountNumber: !isMatch,
			}));
		}
		if (name === 'accountNumber') {
			setDirectDepositeDetails((prev: any) => {
				return {
					...prev,
					isVerified: false,
				};
			});
			falseVerifiedAccount();
		}
	};
	const OnChange = (value: string | number | null, key: string) => {
		setDirectDepositeDetails((prev: any) => {
			return {
				...prev,
				[key]: value,
			};
		});

		const checkFormError = validateFormData(
			{ [key]: value },
			{ ...formError }
		);

		setFormError(checkFormError);
	};
	useEffect(() => {
		const fetchDirectDepositDetails = async () => {
			setIsLoading(true);
			const employeeId = searchParams.get('employeeId');

			if (!employeeId) {
				toastText('Employee ID is missing', 'error');
				setIsLoading(false);
				return;
			}

			try {
				const response =
					await directDepositApi.fetchDirectDepositByEmployeeId(
						employeeId
					);
				const data = response.data;
				setDirectDepositeDetails({
					accountHolderName: data.accountHolderName,
					bankId: data.bankDetailsId,
					accountNumber: data.accountNumber,
					reAccountNumber: data.accountNumber,
					sortCode: data.sortCode,
					isVerified: data.isVerified,
				});
			} catch (err) {
				toastText('Failed to load direct deposit details', 'error');
			} finally {
				setIsLoading(false);
			}
		};

		fetchDirectDepositDetails();
	}, [searchParams]);
	const handleSubmit = async () => {
		let checkFormError = validateFormData(
			{ ...directDepositeDetails },
			{ ...formError }
		);

		setFormError(checkFormError);

		if (directDepositeDetails.isVerified === false) {
			toastText('Account verification failed', 'error');
			return;
		}

		if (hasFormError(checkFormError)) {
			return;
		} else {
			const data = {
				employeeId: searchParams.get('employeeId'),
				bankDetailsId: directDepositeDetails.bankId,
				accountNumber: directDepositeDetails.accountNumber,
				sortCode: directDepositeDetails.sortCode,
				accountHolderName: directDepositeDetails.accountHolderName,
				isVerified: directDepositeDetails.isVerified,
			};

			console.log('Data of createDirectDeposit:', data);
			setIsLoading(true);

			try {
				await directDepositApi.createDirectDeposit(data);
				toastText(
					'Direct Deposit has been saved successfully',
					'success'
				);
			} catch (err: any) {
				const message =
					err.response?.data?.message ||
					'Something went wrong in creating Direct Deposit Request';
				toastText(message, 'error');
			}
			setIsLoading(false);
		}
	};

	const handleVerifySubmit = async () => {
		// Call a api here to get bank details and if get response mark verify as true
		setIsVerifyLoading(true);
		try {
			const response = await companySetup.verifyAccountApi({
				accountId: directDepositeDetails.accountNumber,
			});
			console.log('Response:', response);
			if (response?.data?.data) {
				setDirectDepositeDetails((prev: any) => {
					return {
						...prev,
						isVerified: true,
					};
				});
				trueVerifiedAccount();
				toastText('Account verified successfully', 'success');
			}
		} catch (err: any) {
			falseVerifiedAccount();
			setDirectDepositeDetails((prev: any) => {
				return {
					...prev,
					isVerified: false,
				};
			});
			toastText('Account verification failed', 'error');
		} finally {
			setIsVerifyLoading(false);
		}
	};

	const handleCancel = () => {
		onCancel();
	};

	const verifyButton = {
		text: 'Verify',
		isLoading: isVerifyLoading,
		className: 'primary-button',
		fontSize: '1.8rem',
		minWidth: '12rem',
		minHeight: '4rem',
		isSubmit: true,
		onclick: () => {
			handleVerifySubmit();
		},
	};

	const myButtons = [
		{
			text: 'Save',
			isLoading:
				isLoading ||
				isVerifyLoading ||
				directDepositeDetails.isVerified,
			className: 'primary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: true,
			onclick: handleSubmit,
		},

		{
			text: 'Cancel',
			isLoading: false,
			className: 'secondary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: false,
			onclick: handleCancel,
		},
	];
	const isVerifyButton = [verifyButton];
	// useEffect(() => {
	// 	dispatch(fetchConstantDropdownAction({}));
	// }, []);
	return (
		<div className={styles.directDepositform}>
			<div className={styles['directDepositform-container']}>
				<Row
					className={styles['directDepositform-container-card']}
					gutter={20}
				>
					<Col
						span={24}
						className={`${styles['col']} ${styles['input']} margin-top-10`}
					>
						<InputField
							name="accountHolderName"
							value={directDepositeDetails.accountHolderName}
							label="Account Holder Name"
							required={true}
							helperText="Account Holder must contain letters and spaces"
							onChange={(value: any) =>
								handleChange(value, 'accountHolderName', true)
							}
							isError={formError.accountHolderName}
						/>
					</Col>
					<Col
						span={24}
						className={`${styles['col']} ${styles['input']} margin-top-10`}
					>
						<SelectDropdown
							placeholder="Bank Name"
							options={constantDropdownOptions?.bankData || []}
							value={directDepositeDetails.bankId}
							onChange={(value: any) =>
								handleChange(value, 'bankId', true)
							}
							size="large"
							required={true}
							helperText="Bank Name required"
							label="Bank Name"
							isError={formError.bankId}
						/>
					</Col>
					<Col
						span={!directDepositeDetails.isVerified ? 18 : 24}
						className={styles['col']}
					>
						<InputField
							name="accountNumber"
							value={directDepositeDetails.accountNumber}
							label="Account Number"
							required={true}
							helperText="Account Number required"
							onChange={(value) =>
								handleChange(value, 'accountNumber', true)
							}
							isError={formError.accountNumber}
						/>
					</Col>
					{!directDepositeDetails.isVerified && (
						<Col span={6} className={styles['verfiyButton']}>
							{<Buttons buttons={isVerifyButton} />}
						</Col>
					)}

					<Col span={24} className={`${styles['col']} margin-top-10`}>
						<InputNumberField
							name="sortCode"
							value={directDepositeDetails.sortCode}
							label="Sort Code"
							required={true}
							helperText="Sort code required"
							onChange={(value) =>
								handleChange(value, 'sortCode', true)
							}
							isError={formError.sortCode}
						/>
					</Col>
				</Row>

				<div className={styles['buttons']}>
					<Buttons buttons={myButtons} />
				</div>
			</div>
		</div>
	);
};
export default DirectDeposit;
