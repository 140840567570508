import { PermissionContext } from 'components/Global/AuthLayout';
import ConfigurationSidebar from 'components/Global/ConfigurationSidebar';
import { FORMDATA } from 'constants/Data';
import { FC, useContext, useEffect, useState } from 'react';
import styles from './index.module.scss';
import { SettingsLayoutProps } from './types';

// Settings page layout
const ReportLayout: FC<SettingsLayoutProps> = (props) => {
	const { ReportMenuItems } = FORMDATA;
	const { children, onSideBarChange, selectedSidebar } = props;

	const permissionContext = useContext(PermissionContext);
	const { allowedPermissions } = permissionContext;

	const [menuItems, setMenuItems] = useState(ReportMenuItems);

	// useEffect(() => {
	// 	let _menuItems = ReportMenuItems;

	// 	if (!permissionContext.isSuperAdmin) {
	// 		_menuItems = _menuItems.filter((item) => item?.key !== 'company');
	// 	}
	// 	if (!allowedPermissions.includes('Allow_Branch_Code')) {
	// 		_menuItems = _menuItems.filter(
	// 			(item) => item?.key !== 'branch-code'
	// 		);
	// 	}
	// 	if (!allowedPermissions.includes('Allow_Category_Code')) {
	// 		_menuItems = _menuItems.filter(
	// 			(item) => item?.key !== 'category-code'
	// 		);
	// 	}
	// 	if (!allowedPermissions.includes('Allow_Job_Title')) {
	// 		_menuItems = _menuItems.filter((item) => item?.key !== 'job-title');
	// 	}
	// 	if (!allowedPermissions.includes('Allow_Pay_Point')) {
	// 		_menuItems = _menuItems.filter((item) => item?.key !== 'pay-point');
	// 	}
	// 	if (!allowedPermissions.includes('Allow_Departments')) {
	// 		_menuItems = _menuItems.filter(
	// 			(item) => item?.key !== 'department'
	// 		);
	// 	}
	// 	if (!allowedPermissions.includes('Allow_Cost_Center')) {
	// 		_menuItems = _menuItems.filter(
	// 			(item) => item?.key !== 'cost-center'
	// 		);
	// 	}
	// 	if (!allowedPermissions.includes('Allow_Job_Grade')) {
	// 		_menuItems = _menuItems.filter((item) => item?.key !== 'job-grade');
	// 	}
	// 	// if (!allowedPermissions.includes('Allow_Earning')) {
	// 	// 	_menuItems = _menuItems.filter((item) => item?.key !== 'earning');
	// 	// }
	// 	// if (!allowedPermissions.includes('Allow_Deduction')) {
	// 	// 	_menuItems = _menuItems.filter((item) => item?.key !== 'deduction');
	// 	// }
	// 	if (!allowedPermissions.includes('Allow_Leave_Management')) {
	// 		_menuItems = _menuItems.filter(
	// 			(item) => item?.key !== 'leave-management'
	// 		);
	// 	}
	// 	if (!allowedPermissions.includes('Allow_Holidays')) {
	// 		_menuItems = _menuItems.filter((item) => item?.key !== 'holidays');
	// 	}

	// 	if (permissionContext.isSuperAdmin) {
	// 		_menuItems = [
	// 			{
	// 				key: 'company',
	// 				label: 'Company',
	// 			},
	// 		];
	// 	}

	// 	setMenuItems(_menuItems);
	// }, [ReportMenuItems, permissionContext]);

	// JSX
	return (
		<div className={styles['configuration-layout']}>
			<div className={styles['configuration-layout__wrapper']}>
				<ConfigurationSidebar
					items={menuItems}
					handleSidebar={onSideBarChange}
					selectedSidebar={selectedSidebar as string}
				/>
				<div className={styles['configuration-layout__body']}>
					{children}
				</div>
			</div>
		</div>
	);
};

export default ReportLayout;
