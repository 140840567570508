import { Button, Modal, Table, Tooltip } from 'antd';
import React, { useState } from 'react';
import styles from './index.module.scss';
import Column from 'antd/es/table/Column';
import dayjs from 'dayjs';
import AddModal from '../AddModal';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { DeleteActionSvg, EditActionSvg } from 'utils/svgs';
import { formatNumber, toastText } from 'utils/utils';
import { useSelector } from 'react-redux';
import { companySetup } from 'Api/companySetup';

type TableProps = {
	tableData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	isLoading: boolean;
};

const PayrollTable = (props: TableProps) => {
	const {
		tableData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		isLoading,
	} = props;

	const [isModalVisible, setIsModalVisible] = useState(false);
	const { data } = useSelector((state: any) => state?.userProfile);

	const [bankDetailsData, setBankDetailsData] = useState([]);
	const [isBankLoading, setIsBankLoading] = useState(false);

	// Function to open the modal
	const showModal = () => {
		fetchCompanyBankDetails();
		setIsModalVisible(true);
	};

	// Function to handle modal OK or close actions
	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const fetchCompanyBankDetails = async () => {
		try {
			setIsBankLoading(true);
			const bankDetails = await companySetup.getBankDetails();
			setBankDetailsData(bankDetails.data.data);
		} catch (error: any) {
			let message = 'Something went wrong in fetching bank details';

			if (error.response.data.message) {
				message = error.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsBankLoading(false);
		}
	};

	const staticTableData = [
		{
			id: 1,
			currency: 'ZMW',
			payGroup: 'Monthly Salaries',
			payMonth: 'August',
			total: 4250,
			payDate: '2023-08-25',
			createdBy: 'John Doe',
			status: 'Approved',
			action: 'Approve',
			initiatePayment: true,
		},
		{
			id: 2,
			currency: 'ZMW',
			payGroup: 'Bonus Payments',
			payMonth: 'July',
			total: 4250,
			payDate: '2023-07-15',
			createdBy: 'Jane Smith',
			status: 'Rejected',
			action: 'View',
			initiatePayment: true,
		},
	];

	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={staticTableData}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Pay period"
					dataIndex="payMonth"
					key="payMonth"
					sorter={false}
					className="bg-white"
				/>
				<Column
					title="Pay group"
					dataIndex="payGroup"
					key="payGroup"
					sorter={false}
					className="bg-white"
				/>
				<Column
					title="Currency"
					dataIndex="currency"
					key="currency"
					sorter={false}
					className="bg-white"
				/>

				<Column
					title="Pay Date"
					dataIndex="payDate"
					key="payDate"
					render={(value: string) =>
						dayjs(value).format('DD/MM/YYYY')
					}
					sorter={false}
					className="bg-white"
				/>
				<Column
					title="Submitted By"
					dataIndex="createdBy"
					key="createdBy"
					sorter={false}
					className="bg-white"
				/>
				<Column
					title="Total payable"
					dataIndex="total"
					key="total"
					sorter={false}
					className="bg-white"
					render={(value: string) => {
						return formatNumber(Number(value), data?.baseCurrency);
					}}
				/>
				<Column
					title="Status"
					dataIndex="status"
					key="status"
					sorter={false}
					className="bg-white"
					render={(text) => (
						<span
							style={{
								color:
									text === 'Approved'
										? 'green'
										: text === 'Rejected'
										? 'red'
										: 'black',
							}}
						>
							{text}
						</span>
					)}
				/>
				<Column
					title="Action"
					dataIndex="action"
					key="action"
					sorter={false}
					className="bg-white"
					render={(_, record) => (
						<div>
							<Button
								type="text"
								icon={<EditActionSvg />}
								onClick={() => console.log('Edit', record)}
							/>
							<Button
								type="text"
								icon={
									<EyeOutlined
										style={{
											color: '#000',
											fontSize: '22px',
										}}
									/>
								}
								onClick={() => console.log('View', record)}
							/>
							<Button
								type="text"
								icon={<DeleteActionSvg />}
								onClick={() => console.log('Delete', record)}
							/>
						</div>
					)}
				/>
				<Column
					title="Bank"
					dataIndex="bankFile"
					key="bankFile"
					sorter={false}
					className="bg-white"
					render={(_, record) => (
						<Tooltip title="Download bank file">
							<Button
								type="default"
								icon={<DownloadOutlined />}
							/>
						</Tooltip>
					)}
				/>
				<Column
					title="Payment"
					dataIndex="initiatePayment"
					key="initiatePayment"
					sorter={false}
					className="bg-white"
					render={(_, record) => (
						<Button type="primary" onClick={showModal}>
							Initiate Payment
						</Button>
					)}
				/>
			</Table>
			<AddModal
				isOpen={isModalVisible}
				handleCancel={handleCancel}
				bankDetailsData={bankDetailsData}
				isBankLoading={isBankLoading}
			/>
		</div>
	);
};

export default PayrollTable;
