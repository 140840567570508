import {
	ConfigurationsSvg,
	EmailSvg,
	PasswordSvg,
	PayrollSettings,
	RoleSvg,
	UsersSvg,
} from 'utils/svgs';

const phoneNumberValidator = (_: any, value: any) => {
	// Modify this regex pattern to match the format of phone numbers you want to validate
	const phoneRegex = /^[0-9]{10}$/;

	if (!value || value.match(phoneRegex)) {
		return Promise.resolve();
	}

	return Promise.reject(new Error('Please enter a valid phone number'));
};

const validateNoOnlySpaces = (_: any, value: any) => {
	if (value && value.trim() === '') {
		return Promise.reject('Please enter valid value');
	}
	return Promise.resolve();
};

export const FORMDATA = {
	loginFields: [
		{
			title: 'Email Address',
			id: 'email',
			type: 'text',
			name: 'email',
			svg: <EmailSvg />,
			placeHolder: '',
			required: true,
			rules: [
				{
					required: true,
					message: 'Please enter your email address',
					validateTrigger: 'onChange',
				},
				{
					type: 'email',
					message: 'Please enter valid e-mail',
					validateTrigger: 'onChange',
				},
			],
		},
		{
			title: 'Password',
			id: 'password',
			type: 'password',
			name: 'password',
			svg: <PasswordSvg />,
			placeHolder: '',
			required: true,
			rules: [
				{
					required: true,
					message: 'Please enter your password',
					validateTrigger: 'onChange',
				},
				// {
				// 	pattern:
				// 		/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!])(?!.*\s).{8,16}$/,
				// 	message:
				// 		'Password should have minimum 8 characters at least 1 uppercase, 1 lowercase, 1 number and 1 special character',
				// 	validateTrigger: 'onChange',
				// },
			],
		},
	],
	resetPassword: [
		{
			title: 'New Password',
			id: 'password',
			type: 'password',
			name: 'password',
			svg: <PasswordSvg />,
			placeHolder: '',
			required: true,
			rules: [
				{
					required: true,
					message: 'Please enter your password',
					validateTrigger: 'onChange',
				},
			],
		},
		{
			title: 'Confirm Password',
			id: 'confirmPassword',
			type: 'password',
			name: 'confirmPassword',
			svg: <PasswordSvg />,
			placeHolder: '',
			required: true,
			rules: [
				{
					required: true,
					message: 'Please enter your password again',
					validationTrigger: 'onChange',
				},
				({ getFieldValue }: any) => ({
					validator(_: any, value: any) {
						if (!value || getFieldValue('password') === value) {
							return Promise.resolve();
						}
						return Promise.reject(
							new Error('Passwords do not match')
						);
					},
					validateTrigger: 'onChange',
				}),
			],
		},
	],
	forgotPassword: [
		{
			title: 'Email Address',
			id: 'email',
			type: 'text',
			name: 'email',
			svg: <EmailSvg />,
			placeHolder: '',
			required: true,
			rules: [
				{
					required: true,
					message: 'Please enter your email address',
					validateTrigger: 'onChange',
				},
				{
					type: 'email',
					message: 'Please enter valid e-mail',
					validateTrigger: 'onChange',
				},
			],
		},
	],
	settingsMenuItems: [
		{
			key: 'users',
			icon: <UsersSvg />,
			label: 'Users',
		},
		{
			key: 'roles',
			icon: <RoleSvg />,
			label: 'Roles',
		},
		{
			key: 'configuration',
			icon: <ConfigurationsSvg />,
			label: 'Configuration',
		},
		{
			key: 'payroll-wizard',
			icon: <PayrollSettings />,
			label: 'Payroll Settings',
		},
		{
			key: 'approval',
			icon: <ConfigurationsSvg />,
			label: 'Approval',
		},
		{
			key: 'notification',
			icon: <ConfigurationsSvg />,
			label: 'Notification',
		},
	],
	configurationMenuItems: [
		{
			key: 'company',
			label: 'Company',
		},
		{
			key: 'branch-code',
			label: 'Branch code',
		},
		{
			key: 'category-code',
			label: 'Category code',
		},
		{
			key: 'job-title',
			label: 'Job Title',
		},
		{
			key: 'pay-point',
			label: 'Pay Point',
		},
		{
			key: 'department',
			label: 'Department ',
		},
		{
			key: 'cost-center',
			label: 'Cost Center ',
		},
		{
			key: 'job-grade',
			label: 'Job Grade',
		},
		// {
		// 	key: 'earning',
		// 	label: 'Earning',
		// },
		// {
		// 	key: 'deduction',
		// 	label: 'Deduction',
		// },
		{
			key: 'leave-management',
			label: 'Leave Management',
		},
		{
			key: 'holidays',
			label: 'Holidays',
		},
	],
	PayrollSettingsMenuItems: [
		{
			key: 'company-setup',
			label: 'Company Setup',
		},
		{
			key: 'pay-period',
			label: 'Pay Period',
		},
		{
			key: 'pay-group',
			label: 'Pay Group',
		},
		{
			key: 'statutory-components',
			label: 'Statutory Components',
		},
		{
			key: 'earning-code',
			label: 'Earnings Code',
		},
		{
			key: 'deduction-code',
			label: 'Deduction Code',
		},
		{
			key: 'mid-month-pay',
			label: 'Mid-Month Pay',
		},
		// {
		// 	key: 'payroll-history',
		// 	label: 'Payroll history',
		// },
	],
	ReportMenuItems: [
		{
			key: 'napsa',
			label: 'NAPSA',
		},
		{
			key: 'nhima',
			label: 'NHIMA',
		},
		{
			key: 'paye',
			label: 'PAYE',
		},
		{
			key: 'p-18',
			label: 'P-18',
		},
		{
			key: 'payroll-summary',
			label: 'Payroll Summary',
		},
	],
	approvalMenuItems: [
		{
			key: 'employee',
			label: 'Employee',
		},
		// {
		// 	key: 'time-sheet',
		// 	label: 'Time Sheet',
		// },
		// {
		// 	key: 'payroll',
		// 	label: 'Payroll',
		// },
	],
	pageMenuItems: [
		{
			key: 'dashboard',
			label: 'Dashboard',
		},
		{
			key: 'employees',
			label: 'Employees',
		},
		{
			key: 'time-activities-main',
			label: 'Time Activities',
			children: [
				{
					key: 'shifts',
					label: 'Time Shift',
				},
				{
					key: 'time-logs',
					label: 'Time Logs',
				},
				{
					key: 'time-activities',
					label: 'Time Activities',
				},
				{
					key: 'time-sheets',
					label: 'Time Sheets',
				},
				{
					key: 'leave-request',
					label: 'Leave Request',
				},
			],
		},
		{
			key: 'run-payroll-main',
			label: 'Payroll',
			children: [
				{
					key: 'mid-month',
					label: 'Mid Month Pay',
				},
				{
					key: 'loan-request',
					label: 'Loan Request',
				},
				{
					key: 'calculate-statutory-components',
					label: 'Calculate Statutory Components',
				},

				{
					key: 'run-payroll',
					label: 'Run Payroll',
				},
			],
		},
		{
			key: 'reports',
			label: 'Report',
			// children: [
			// 	{
			// 		key: 'napsa',
			// 		label: 'NAPSA',
			// 	},
			// 	{
			// 		key: 'nhima',
			// 		label: 'NHIMA',
			// 	},
			// 	{
			// 		key: 'paye',
			// 		label: 'PAYE',
			// 	},
			// 	{
			// 		key: 'p-18',
			// 		label: 'P-18',
			// 	},
			// 	{
			// 		key: 'payroll-summary',
			// 		label: 'Payroll Summary',
			// 	},
			// ],
		},
	],
	addUserFields: [
		{
			title: 'Full Name',
			id: 'fullName',
			type: 'text',
			name: 'fullName',
			placeHolder: '',
			required: true,
			rules: [
				{
					required: true,
					message: 'Please enter your full name',
					validateTrigger: 'onChange',
				},
				{
					max: 30,
					message: 'Full name length must be less than 30 characters',
					validateTrigger: 'onChange',
				},
			],
		},
		{
			title: 'Select Role',
			id: 'roleName',
			type: 'text',
			name: 'roleName',
			defaultValue: '',
			placeholder: 'Enter role name',
			placeHolder: '',
			required: true,
			rules: [
				{
					required: true,
					message: 'Please select role',
					validateTrigger: 'onChange',
				},
			],
		},
		{
			title: 'Email Address',
			id: 'email',
			type: 'text',
			name: 'email',
			placeHolder: '',
			required: true,
			rules: [
				{
					required: true,
					message: 'Please enter email address',
					validateTrigger: 'onChange',
				},
				{
					type: 'email',
					message: 'Please enter valid email address',
					validateTrigger: 'onChange',
				},
			],
		},
		// {
		// 	title: 'Phone Number',
		// 	id: 'phone',
		// 	type: 'text',
		// 	name: 'phone',
		// 	placeHolder: '',
		// 	required: true,
		// 	rules: [
		// 		{
		// 			validator: phoneNumberValidator,
		// 			validateTrigger: 'onChange',
		// 		},
		// 	],
		// },
	],
	addRoleFields: [
		{
			title: 'Role Name',
			id: 'roleName',
			type: 'text',
			name: 'roleName',
			defaultValue: '',
			placeholder: 'Enter role name',
			placeHolder: '',
			required: true,
			rules: [
				{
					required: true,
					message: 'Please enter role name',
					validateTrigger: 'onChange',
				},
				{
					max: 50,
					message: 'Role name length must be less than 50 characters',
					validateTrigger: 'onChange',
				},
				{ validator: validateNoOnlySpaces },
			],
		},
		{
			title: 'Description',
			id: 'roleDescription',
			type: 'textArea',
			name: 'roleDescription',
			defaultValue: '',
			placeholder: 'Enter role description',
			placeHolder: '',
			required: true,
			rules: [
				{
					required: true,
					message: 'Please enter role description',
					validateTrigger: 'onSubmit',
				},
				{
					max: 200,
					message:
						'Role description length must be less than 200 characters',
					validateTrigger: 'onChange',
				},
				{ validator: validateNoOnlySpaces },
			],
		},
	],
};

// export const userColumns = [
// 	{
// 		title: 'Organization Name',
// 		dataIndex: 'name',
// 		key: 'name',
// 		sorter: (a: any, b: any) => {
// 			return a.name.length - b.name.length;
// 		},
// 		sortDirections: ['descend'],
// 	},
// 	{
// 		title: 'Email Address',
// 		dataIndex: 'email',
// 		key: 'email',
// 	},
// 	{
// 		title: 'Phone number',
// 		dataIndex: 'phone',
// 		key: 'phone',
// 	},
// 	{
// 		title: 'Role',
// 		dataIndex: 'role',
// 		key: 'role',
// 	},
// 	{
// 		title: 'Action',
// 		dataIndex: 'action',
// 		key: 'action',
// 	},
// ];

export const roleDataSource: any = [];
roleDataSource.push({
	name: `Admin`,
	description: `Description for role`,
	permissions: `Permission Details`,
	status: true,
	action: `some action`,
});

for (let index = 0; index < 15; index++) {
	roleDataSource.push({
		name: `Role ${index}`,
		description: `Description for role ${index}`,
		permissions: `Permission Details`,
		status: index % 2 == 0 ? true : false,
		action: `some action`,
	});
}

export const roleColumns = [
	{
		title: 'Role Name',
		dataIndex: 'name',
		key: 'name',
		sorter: (a: any, b: any) => {
			return a.name.length - b.name.length;
		},
		sortDirections: ['descend'],
	},
	{
		title: 'Description',
		dataIndex: 'description',
		key: 'description',
	},
	{
		title: 'Status',
		dataIndex: 'status',
		key: 'status',
	},
	{
		title: 'Permissions',
		dataIndex: 'permissions',
		key: 'permissions',
	},
	{
		title: 'Action',
		dataIndex: 'action',
		key: 'action',
	},
];

export const UserProfileData = [
	{
		title: 'First Name',
		id: 'firstName',
		type: 'text',
		name: 'firstName',
		defaultValue: '',
		disabled: false,
		errorMessage: 'Please enter your first name',
		required: true,
		rules: [
			{
				required: true,
				message: 'Please enter your first name',
				validateTrigger: 'onChange',
			},
			{
				min: 2,
				max: 40,
				message: 'First name must be between 2 and 40 characters',
				validateTrigger: 'onChange',
			},
		],
	},
	{
		title: 'Last Name',
		id: 'lastName',
		type: 'text',
		name: 'lastName',
		defaultValue: '',
		disabled: false,
		errorMessage: 'Please enter your last name',
		required: true,
		rules: [
			{
				required: true,
				message: 'Please enter your last name',
				validateTrigger: 'onChange',
			},
			{
				min: 2,
				max: 40,
				message: 'Last name must be between 2 and 40 characters',
				validateTrigger: 'onChange',
			},
		],
	},
	{
		title: 'Email Address',
		id: 'email',
		type: 'text',
		name: 'email',
		defaultValue: '',
		disabled: true,
		errorMessage: 'Please enter your email',
		required: false,
		rules: [],
	},
	{
		title: 'Role',
		id: 'role',
		type: 'text',
		name: 'role',
		defaultValue: '',
		disabled: true,
		errorMessage: 'Please enter your role',
		required: true,
		rules: [
			{
				required: true,
				message: 'Please enter your role',
				validateTrigger: 'onChange',
			},
			{
				min: 2,
				max: 40,
				message: 'Role must be between 2 and 40 characters',
				validateTrigger: 'onChange',
			},
		],
	},
	// {
	// 	title: 'Phone Number',
	// 	id: 'phone',
	// 	type: 'number',
	// 	name: 'phone',
	// 	required: false,
	// 	rules: [
	// 		{
	// 			validator: phoneNumberValidator,
	// 			message: 'Please enter valid phone number',
	// 			validateTrigger: 'onChange',
	// 		},
	// 	],
	// },
];

export const APIKey = [
	{
		title: 'API Key',
		id: 'apiKey',
		type: 'text',
		name: 'apiKey',
		defaultValue: '',
		disabled: false,
		errorMessage: 'Please enter API key',
		required: true,
		rules: [
			{
				required: true,
				message: 'Please enter API key',
				validateTrigger: 'onChange',
			},
			{
				min: 10,
				message: 'API key length must contain at least 10 characters',
				validateTrigger: 'onChange',
			},
			{
				max: 150,
				message: 'API key length must be less than 150 characters',
				validateTrigger: 'onChange',
			},
		],
	},
];

export const monthNames = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

export const months = [
	{ value: 1, label: 'January' },
	{ value: 2, label: 'February' },
	{ value: 3, label: 'March' },
	{ value: 4, label: 'April' },
	{ value: 5, label: 'May' },
	{ value: 6, label: 'June' },
	{ value: 7, label: 'July' },
	{ value: 8, label: 'August' },
	{ value: 9, label: 'September' },
	{ value: 10, label: 'October' },
	{ value: 11, label: 'November' },
	{ value: 12, label: 'December' },
];

export const booleanOptions = [
	{
		label: 'Yes',
		value: 'true',
	},
	{
		label: 'No',
		value: 'false',
	},
];

export const Earnings = [
	{
		id: Math.random(),
		isChecked: true,
		value: '180.00',
		isDefault: true,
		currency: 'K',
		label: 'Housing Allowance',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: true,
		currency: 'K',
		label: 'Transport Allowance',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: true,
		currency: 'K',
		label: 'Weekdays Overtime',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: true,
		currency: 'K',
		label: 'Leave Pay',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: true,
		currency: 'K',
		label: 'Sunday Overtime',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: true,
		currency: 'K',
		label: 'Gratuity Pay',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: true,
		currency: 'K',
		label: 'Lunch Allowance',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: true,
		currency: 'K',
		label: 'House Rent',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: true,
		currency: 'K',
		label: 'Seventh Day Overtime',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: true,
		currency: 'K',
		label: 'Shift Differential Pay',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'MEDICAL ALLOWANCE',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'ACTING ALLOWANCE',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'WATER ALLOWANCE',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'DOMESTIC SERV.ALLOW.',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'PHONE ALLOWANCE',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'TERMINAL BENEFITS',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'NIGHT ALLOWANCE',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'ELECTRICITY ALLOWANCE',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'HOUSING ALLOW.ARREAS',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'SERVICE BENEFIT',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'XMAS BONUS',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'ELECT.ALOW.ARREARS',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'WAT.ALLOW.ARREARS',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'RET.PACKAGE',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'REPATRIATION',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'INDUCEMENT ALLOWANCE',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'FOOD ALLOW. ADJ.',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'REFUND: STAFF DEBTOR',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'SALARY ADJUSTMENT',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'TRANSPORT ALL.ARREAR',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'TERMIN.LVE PAY',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'BASIC PAY RECOV.',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'Christmas Bonus',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'REFUND NAPSA',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'BASIC PAY',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'ABSENT PAY',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'LEAVE COMMUTATION',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'CHRISTMAS BONUS',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'COY NAPSA CONTR.',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'COY PENSION CONTR.',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'ROUNDING ADJ.',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'LEAVE PROVISION',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'CHRISTMAS BONUS PROVISION',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'Skills Development Levy',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'Gross up Tax Refund',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'NHIMA EMPLOYER CONTRIBUTION',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'LASF EMPLOYER CONTRIBUTION',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'MADSON FUNERAL POLICY EMPLOYER',
	},
];

export const Deductions = [
	{
		id: Math.random(),
		isChecked: false,
		value: '',
		isDefault: false,
		currency: 'K',
		label: 'PAYE',
	},
	{
		id: Math.random(),
		isChecked: true,
		value: 0,
		isDefault: false,
		currency: 'K',
		label: 'NHIMA',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: 0,
		isDefault: false,
		currency: 'K',
		label: 'Mid Month',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: 0,
		isDefault: false,
		currency: 'K',
		label: 'Meals/Sundry Deduction',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: 0,
		isDefault: false,
		currency: 'K',
		label: 'NAPSA',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: 0,
		isDefault: false,
		currency: 'K',
		label: 'House Loan',
	},
	{
		id: Math.random(),
		isChecked: false,
		value: 0,
		isDefault: false,
		currency: 'K',
		label: 'Medical Fund',
	},
	{
		id: Math.random(),
		isChecked: true,
		value: 0,
		isDefault: false,
		currency: 'K',
		label: 'Union Contribution',
	},
];

export const EmployeeData = [
	{
		id: Math.random(),
		label: 'First Name',
		fieldName: 'firstName',
		sendApproval: false,
		sectionName: 'Personal Details',
		moduleName: 'EMPLOYEE',
	},
	{
		id: Math.random(),
		label: 'Last Name',
		fieldName: 'lastName',
		sendApproval: false,
		sectionName: 'Personal Details',
		moduleName: 'EMPLOYEE',
	},
	{
		id: Math.random(),
		label: 'DOB',
		fieldName: 'dateOfBirth',
		sendApproval: false,
		sectionName: 'Personal Details',
		moduleName: 'EMPLOYEE',
	},
	{
		id: Math.random(),
		label: 'Gender',
		fieldName: 'gender',
		sendApproval: false,
		sectionName: 'Personal Details',
		moduleName: 'EMPLOYEE',
	},
	{
		id: Math.random(),
		label: 'Country',
		fieldName: 'country',
		sendApproval: false,
		sectionName: 'Personal Details',
		moduleName: 'EMPLOYEE',
	},
	{
		id: Math.random(),
		label: 'Marital Status',
		fieldName: 'maritalStatus',
		sendApproval: false,
		sectionName: 'Personal Details',
		moduleName: 'EMPLOYEE',
	},
	{
		id: Math.random(),
		label: 'SSN',
		fieldName: 'ssn',
		sendApproval: false,
		sectionName: 'Identification Details',
		moduleName: 'EMPLOYEE',
	},
	{
		id: Math.random(),
		label: 'Passport No.',
		fieldName: 'passportNo',
		sendApproval: false,
		sectionName: 'Identification Details',
		moduleName: 'EMPLOYEE',
	},
	{
		id: Math.random(),
		label: 'National Registration No.',
		fieldName: 'nationalRegistrationNo',
		sendApproval: false,
		sectionName: 'Identification Details',
		moduleName: 'EMPLOYEE',
	},
	{
		id: Math.random(),
		label: 'Record Status',
		fieldName: 'recordStatus',
		sendApproval: false,
		sectionName: 'Identification Details',
		moduleName: 'EMPLOYEE',
	},
	{
		id: Math.random(),
		label: 'Joining Date',
		fieldName: 'joiningDate',
		sendApproval: false,
		sectionName: 'Employee Details',
		moduleName: 'EMPLOYEE',
	},
	{
		id: Math.random(),
		label: 'Branch',
		fieldName: 'branch',
		sendApproval: false,
		sectionName: 'Employee Details',
		moduleName: 'EMPLOYEE',
	},
	{
		id: Math.random(),
		label: 'Department',
		fieldName: 'department',
		sendApproval: false,
		sectionName: 'Employee Details',
		moduleName: 'EMPLOYEE',
	},
	{
		id: Math.random(),
		label: 'Department',
		fieldName: 'department',
		sendApproval: false,
		sectionName: 'Employee Details',
		moduleName: 'EMPLOYEE',
	},
	{
		id: Math.random(),
		label: 'Cost Center',
		fieldName: 'costCenter',
		sendApproval: false,
		sectionName: 'Employee Details',
		moduleName: 'EMPLOYEE',
	},
	{
		id: Math.random(),
		label: 'Category',
		fieldName: 'category',
		sendApproval: false,
		sectionName: 'Employee Details',
		moduleName: 'EMPLOYEE',
	},
	{
		id: Math.random(),
		label: 'Job Grade',
		fieldName: 'jobGrade',
		sendApproval: false,
		sectionName: 'Employee Details',
		moduleName: 'EMPLOYEE',
	},
	{
		id: Math.random(),
		label: 'Staff Code',
		fieldName: 'staffCode',
		sendApproval: false,
		sectionName: 'Employee Details',
		moduleName: 'EMPLOYEE',
	},
];

export const TaxationData = [
	{
		id: Math.random(),
		label: 'Employee TPIN',
		isChecked: false,
	},
	{
		id: Math.random(),
		label: 'Taxation Method',
		isChecked: false,
	},
	{ id: Math.random(), label: 'Salary', isChecked: false },
	{
		id: Math.random(),
		label: 'Enable Gross up tax?',
		isChecked: false,
	},
	{
		id: Math.random(),
		label: 'Gross up all earnings',
		isChecked: false,
	},
	{ id: Math.random(), label: 'Currency', isChecked: false },
	{
		id: Math.random(),
		label: 'Employment Type',
		isChecked: false,
	},
	{
		id: Math.random(),
		label: 'NAPSA Paid By',
		isChecked: false,
	},
	{
		id: Math.random(),
		label: 'Employee with Disabilities',
		isChecked: false,
	},
	{
		id: Math.random(),
		label: 'Termination Date',
		isChecked: false,
	},
];

export const configurationModules = [
	'Branch Code',
	'Departments',
	'Job Grade',
	'Payment Type',
	'Payment Method',
	'Pay Point',
	'Earning',
	'Deduction',
	'Cost Center',
	'Category',
	'Job Title',
	'Leave Management',
	'Holidays',
];

export const payrollModules = [
	'Company Setup',
	'Pay Period',
	'Statutory Components',
	'Pay Schedule',
	'Payroll History',
	'Mid-Month Pay',
	'Earning Code',
	'Deduction Code',
];

export const deductionFormula = {
	D1: 'First K 4, 000.00 @ 0% - 0, Next K 800.00 @ 25% - 200.00, Next K 2, 100.00 @ 30% - 630.00, Balance K 8, 100.00 @ 37.5% - 3,037.50',
	D2: '10% of total earnings (5% employee share/5% employer share)',
	D3: '2% of Basic pay (1% employee share / 1% employer share)',
	D4: 'K 200 fix as of now',
	D5: 'deduct in March and June - 7.5K in both the months',
	D6: '0.5% of total Gross emoluments',
};
export const earningsFormulaMapping = {
	Salrate: 'SAL',
	OThrs: 'OTH',
	BasicPay: 'BSP',
	leavedays: 'LVD',
	Shiftdiffhrs: 'SDH',
	noofmonthsworked: 'NMW',
};

export const deductionFormulaOptions = [
	{
		value: 'D1',
		label: 'D1',
	},
	{
		value: 'D2',
		label: 'D2',
	},
	{
		value: 'D3',
		label: 'D3',
	},
	{
		value: 'D4',
		label: 'D4',
	},
	{
		value: 'D5',
		label: 'D5',
	},
	{
		value: 'D6',
		label: 'D6',
	},
];

export const displayFormatdropdownOptions = [
	{ label: '1,234,4567', value: 1 },
	{ label: '12,34,567', value: 2 },
];

export const currencyPositionDropdownOptions = [
	{ label: 'Before', value: 'BEFORE' },
	{ label: 'After', value: 'AFTER' },
];

export const decimalPlacesdropdownOptions = [
	{ label: '0', value: 0 },
	{ label: '1', value: 1 },
	{ label: '2', value: 2 },
	{ label: '3', value: 3 },
	{ label: '4', value: 4 },
];

export const thousandSeparatordropdownOptions = [
	{ label: '(.)Dot', value: 'DOT' },
	{ label: '(,)Coma', value: 'COMA' },
];

export const negativeNumberdropdownOptions = [
	{ label: 'with negative sign(eg:- -123)', value: 'MINUS' },
	{ label: 'within parenthesis(eg:- (123))', value: 'BRACKETS' },
];

export const dateFormats = [
	{ label: 'MM/DD/YYYY', value: 'MM/DD/YYYY' },
	{ label: 'DD/MM/YYYY', value: 'DD/MM/YYYY' },
	{ label: 'YYYY/MM/DD', value: 'YYYY/MM/DD' },
];

export const monthsForCompanySetup = [
	{ label: 'January', value: 1 },
	{ label: 'February', value: 2 },
	{ label: 'March', value: 3 },
	{ label: 'April', value: 4 },
	{ label: 'May', value: 5 },
	{ label: 'June', value: 6 },
	{ label: 'July', value: 7 },
	{ label: 'August', value: 8 },
	{ label: 'September', value: 9 },
	{ label: 'October', value: 10 },
	{ label: 'November', value: 11 },
	{ label: 'December', value: 12 },
];
