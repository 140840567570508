import { Table } from 'antd';
import styles from './index.module.scss';
import Ellipse from 'components/Global/Ellipse';

const { Column } = Table;

const tempData = [
	{
		id: 1,
		payPeriodName: 'January 2021',
		createdAt: '2021-01-01',
		createdBy: 'Admin',
		employeeCount: 100,
		Report: 'Download',
	},
	{
		id: 2,
		payPeriodName: 'February 2021',
		createdAt: '2021-02-01',
		createdBy: 'Admin',
		employeeCount: 100,
		Report: 'Download',
	},
	{
		id: 3,
		payPeriodName: 'March 2021',
		createdAt: '2021-03-01',
		createdBy: 'Admin',
		employeeCount: 100,
		Report: 'Download',
	},
	{
		id: 4,
		payPeriodName: 'April 2021',
		createdAt: '2021-04-01',
		createdBy: 'Admin',
		employeeCount: 100,
		Report: 'Download',
	},
	{
		id: 5,
		payPeriodName: 'May 2021',
		createdAt: '2021-05-01',
		createdBy: 'Admin',
		employeeCount: 100,
		Report: 'Download',
	},
	{
		id: 6,
		payPeriodName: 'June 2021',
		createdAt: '2021-06-01',
		createdBy: 'Admin',
		employeeCount: 100,
		Report: 'Download',
	},
	{
		id: 7,
		payPeriodName: 'July 2021',
		createdAt: '2021-07-01',
		createdBy: 'Admin',
		employeeCount: 100,
		Report: 'Download',
	},
	{
		id: 8,
		payPeriodName: 'August 2021',
		createdAt: '2021-08-01',
		createdBy: 'Admin',
		employeeCount: 100,
		Report: 'Download',
	},
	{
		id: 9,
		payPeriodName: 'September 2021',
		createdAt: '2021-09-01',
		createdBy: 'Admin',
		employeeCount: 100,
		Report: 'Download',
	},
	{
		id: 10,
		payPeriodName: 'October 2021',
		createdAt: '2021-10-01',
		createdBy: 'Admin',
		employeeCount: 100,
		Report: 'Download',
	},
];

type Props = {
	ReportData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	isLoading: boolean;
};

const GlobalReportTable = (props: Props) => {
	const {
		ReportData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		isLoading,
	} = props;

	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={ReportData}
				scroll={{ y: 'calc(100vh - 360px)' }}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record: any) => record.id}
				loading={isLoading}
			>
				<Column
					title="Pay Period Name"
					dataIndex="payPeriodName"
					key="payPeriodName"
					//	sorter={true}
					className="bg-white"
				/>
				<Column
					title="Generated Date"
					dataIndex="createdAt"
					key="createdAt"
					//	sorter={true}
					className="bg-white"
				/>
				<Column
					title="Generated By"
					dataIndex="createdBy"
					key="createdBy"
					className="bg-white"
					//	sorter={true}
				/>
				<Column
					title="No. of Employees"
					dataIndex="employeeCount"
					key="employeeCount"
					//	sorter={true}
					className="bg-white"
				/>
				<Column
					title="Report"
					dataIndex="report"
					key="report"
					className="bg-white"
					render={(text, record: any) => (
						<div>
							{record.report ? (
								<a
									href={record.documentLink}
									rel="noopener noreferrer"
								>
									<Ellipse
										message={record.report}
										maxLength={20}
										key={record.report}
										tooltipMessage={record.report}
										isTooltip={true}
									/>
								</a>
							) : (
								'-'
							)}
						</div>
					)}
				/>
			</Table>
		</div>
	);
};

export default GlobalReportTable;
