import { Radio, Table } from 'antd';
import styles from './index.module.scss';

const { Column } = Table;

type Props = {
	netPayData: any;
	isLoading: boolean;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
};
const NetPayTable = (props: Props) => {
	const { netPayData, isLoading, tableChangeHandler } = props;

	const totalRow = {
		id: 'total',
		firstName: 'Total',
		lastName: '',
		optionalEmployeeId: '',
		leaves: '',
		deductionType: '',
		totalEarnings: netPayData.reduce(
			(sum: any, record: any) => sum + record.totalEarnings,
			0
		),
		totalDeductions: netPayData.reduce(
			(sum: any, record: any) => sum + record.totalDeductions,
			0
		),
		netPay: netPayData.reduce(
			(sum: any, record: any) => sum + record.netPay,
			0
		),
	};

	const dataWithTotal: any = [...netPayData, totalRow];

	const totalNetPay = netPayData.reduce(
		(sum: any, record: any) => sum + record.netPay,
		0
	);

	return (
		<>
			<div className={styles['netPay-dynamic-table']}>
				<Table
					dataSource={netPayData}
					//	scroll={{ y: 'calc(90vh - 360px)' }}
					pagination={false}
					onChange={tableChangeHandler}
					rowKey={(record) => record.id}
					loading={isLoading}
				>
					<Column
						title="Employee Name and Code"
						dataIndex="optionalEmployeeId"
						key="optionalEmployeeId"
						sorter={true}
						width={'40%'}
						className="bg-white"
						render={(text, record: any) => (
							<>
								<span>
									{record.id === 'total'
										? record.firstName
										: `${record.firstName} ${record.lastName}`}{' '}
								</span>
								{record.id !== 'total' && (
									<>
										|{' '}
										<span className="color-purple">
											{record?.optionalEmployeeId}
										</span>
									</>
								)}
							</>
						)}
					/>

					<Column
						title="Total Earnings"
						dataIndex="totalEarnings"
						key="totalEarnings"
						className="bg-white"
						width={'20%'}
						sorter={true}
						render={(value) => `${value?.toFixed(2)} ZMW`}
					/>
					<Column
						title="Total Deductions"
						key="totalDeductions"
						dataIndex="totalDeductions"
						className="bg-white"
						width={'20%'}
						sorter={true}
						render={(value) => `${value?.toFixed(2)} ZMW`}
					/>
					<Column
						title="Total Pay"
						dataIndex="netPay"
						key="netPay"
						className="bg-white"
						width={'20%'}
						sorter={true}
						render={(value) => `${value?.toFixed(2)} ZMW`}
					/>
				</Table>

				<div className={styles['netPay-total-row']}>
					<div className={styles['align-net-pay']}>
						<span className={styles['cost-to-company-label']}>
							Cost to Company:
						</span>
						<span className={styles['cost-to-company-value']}>
							{totalNetPay?.toFixed(2)} ZMW
						</span>
					</div>
				</div>
			</div>
		</>
	);
};
export default NetPayTable;
