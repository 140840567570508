import { CloseOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Modal, Typography } from 'antd';
import Title from 'antd/es/typography/Title';
import { bankIntegrationApi } from 'Api/bankIntegration';
import { Loader } from 'components/Global';
import { useState } from 'react';
import { bankMapping } from 'utils/mappings';
import { toastText } from 'utils/utils';
import AddPreviewModal from '../AddPreviewModel';
import styles from './index.module.scss';

type Props = {
	isOpen: boolean;
	handleCancel: () => void;
	isBankLoading: boolean;
	bankDetailsData: any;
};

const AddModal = (props: Props) => {
	const { isOpen, handleCancel, isBankLoading, bankDetailsData } = props;
	const [selectedBank, setSelectedBank] = useState<number>(1);
	const [isPreviewOpen, setIsPreviewOpen] = useState(false);
	const [checkedBankId, setCheckedBankId] = useState<number | null>(null);

	const handleBankSelect = (bankId: number) => {
		setSelectedBank(bankId);
	};

	const handlePreview = () => {
		setIsPreviewOpen(true);
	};

	const handlePreviewCancel = () => {
		setIsPreviewOpen(false);
	};

	const handleProceedToPayment = () => {
		// Logic for proceeding to payment
		console.log(
			'Proceeding to payment with selected bank ID:',
			selectedBank
		);
		setIsPreviewOpen(false);
	};

	const handleCheckBalance = async (bankId: number) => {
		console.log('BANK iD: ', bankId);
		// setCheckedBankId(bankId === checkedBankId ? null : bankId);
		try {
			const response = await bankIntegrationApi.checkBankBalance({
				bankId: bankId,
			});

			console.log('Response: ', response);
		} catch (error: any) {
			let message =
				error?.data?.message ||
				`Something went wrong in checking bank balance.`;
			toastText(message, 'error');
		}
	};

	return (
		<>
			<Modal
				open={isOpen}
				closable={false}
				onCancel={handleCancel}
				width={500}
				footer={
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-start',
						}}
					>
						<Button key="cancel" onClick={handleCancel}>
							Cancel
						</Button>

						<Button
							key="preview"
							onClick={handlePreview}
							type="primary"
							style={{ marginLeft: '10px' }}
						>
							Preview
						</Button>
					</div>
				}
			>
				<div className={`${styles['modal']} modal`}>
					<div className={styles['modal-header']}>
						<Title level={4}>Select Bank</Title>
						<Button
							icon={<CloseOutlined />}
							onClick={handleCancel}
						/>
					</div>
					{isBankLoading && <Loader />}
					{!isBankLoading && (
						<div className={styles['modal-body']}>
							{bankDetailsData &&
								bankDetailsData.length > 0 &&
								bankDetailsData.map((item: any) => {
									console.log('item: ', item);
									const bank = bankMapping[item.bankType];
									return (
										<div
											key={item.id}
											className={`${
												styles['bank-option']
											} ${
												selectedBank === item.id
													? styles['selected']
													: ''
											}`}
											onClick={() =>
												handleBankSelect(item.id)
											}
										>
											<div
												className={
													styles['bank-details']
												}
											>
												<img
													src={bank?.logo}
													height={60}
												/>
											</div>
											{checkedBankId === item.id && (
												<div
													className={
														styles['balance-info']
													}
												>
													<Typography.Text
														type="secondary"
														className={
															styles[
																'available-balance'
															]
														}
													>
														Available balance
													</Typography.Text>
													<div
														className={
															styles[
																'balance-amount'
															]
														}
													>
														{bank.balance}
													</div>
												</div>
											)}

											{item.isVerified &&
												item.bankType ==
													'FIRST_CAPITAL_BANK' && (
													<Button
														type="default"
														className={
															styles[
																'check-balance-button'
															]
														}
														onClick={() => {
															if (
																item.isVerified
															) {
																handleCheckBalance(
																	item.id
																);
															}
														}}
														disabled={
															!item.isVerified ||
															item.bankType !==
																'FIRST_CAPITAL_BANK'
														}
													>
														<EyeOutlined
															style={{
																marginRight:
																	'2px',
															}}
														/>
														Check balance
													</Button>
												)}
										</div>
									);
								})}
						</div>
					)}
				</div>
			</Modal>

			<AddPreviewModal
				isOpen={isPreviewOpen}
				handleCancel={handlePreviewCancel}
				handleProceedToPayment={handleProceedToPayment}
			/>
		</>
	);
};

export default AddModal;
