import { deleteApiWithData, getApi, postApi, putApi } from 'apis';

const deleteCompanyCurrency = async (data: any) => {
	return await deleteApiWithData('/companyCurrency', data);
};

const createCompanyCurrency = async (data: any) => {
	return await postApi('/companyCurrency/', data);
};

const geteCompanyCurrency = async (id : any) => {
	return await getApi('companyCurrency' , id)
}

export const companyCurrencyApi = {
createCompanyCurrency,
deleteCompanyCurrency
}