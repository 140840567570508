import { Col, Row } from 'antd';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EmployeeLeaveImage } from 'utils/svgs';
import { toastText } from 'utils/utils';
import EmployeeLeavesTable from './Table';
import styles from './index.module.scss';
import GlobalHeader from '../GlobalHeader';

type EmployeeLeavesProps = {
	isLoading: boolean;
	employeeData: any;
	handleCheckboxChange: any;
	tableChangeHandler: (filter: any, sorter: any) => void;
};
const EmployeeLeavesComponent = (props: EmployeeLeavesProps) => {
	const {
		employeeData,
		isLoading,
		handleCheckboxChange,
		tableChangeHandler,
	} = props;
	const navigate = useNavigate();

	return (
		<>
			<Row>
				<Col span={16}>
					<div className={styles['employee-leaves__table']}>
						<GlobalHeader />
						<EmployeeLeavesTable
							isLoading={isLoading}
							tableChangeHandler={tableChangeHandler}
							handleCheckboxChange={handleCheckboxChange}
							employeeData={employeeData}
						/>
					</div>
				</Col>
				<Col span={1}></Col>
				<Col span={7}>
					<div>
						<EmployeeLeaveImage />
					</div>
				</Col>
			</Row>
		</>
	);
};

export default EmployeeLeavesComponent;
