export const employeeLeavesData = [
	{
		id: '1',
		firstName: 'John',
		lastName: 'Doe',
		optionalEmployeeId: 'CFL001',
		leaves: '05',
	},
	{
		id: '2',
		firstName: 'Jane',
		lastName: 'Smith',
		optionalEmployeeId: 'CFL002',
		leaves: '03',
	},
	{
		id: '3',
		firstName: 'Alice',
		lastName: 'Johnson',
		optionalEmployeeId: 'CFL003',
		leaves: '07',
	},
	{
		id: '4',
		firstName: 'Bob',
		lastName: 'Brown',
		optionalEmployeeId: 'CFL004',
		leaves: '02',
	},
];

export const workingHoursData = [
	{
		id: 1,
		firstName: 'John',
		lastName: 'Doe',
		employeeCode: 'CFL001',
		regularHours: '40:00',
		weekdaysOT: ' 5:00',
		sundayOT: '2:00',
		timeShiftHours: ' 3:00',
		hourlySalary: '20 ZMW',
		totalHours: '50:00',
	},
	{
		id: 2,
		firstName: 'Jane',
		lastName: 'Smith',
		employeeCode: 'CFL002',
		regularHours: '38:00',
		weekdaysOT: ' 6:00',
		sundayOT: '3:00',
		timeShiftHours: '4:00',
		hourlySalary: '22 ZMW',
		totalHours: '51:00',
	},
	{
		id: 3,
		firstName: 'Michael',
		lastName: 'Johnson',
		employeeCode: 'CFL003',
		regularHours: '35:00',
		weekdaysOT: '8:00',
		sundayOT: '1:00',
		timeShiftHours: ' 2:00',
		hourlySalary: '18 ZMW',
		totalHours: '46:00',
	},
	{
		id: 6,
		firstName: 'Sarah',
		lastName: 'Williams',
		employeeCode: 'CFL006',
		regularHours: '37:30',
		weekdaysOT: '5:15',
		sundayOT: '2:45',
		timeShiftHours: '3:30',
		hourlySalary: '19 ZMW',
		totalHours: '48:00',
	},
	{
		id: 7,
		firstName: 'David',
		lastName: 'Anderson',
		employeeCode: 'CFL007',
		regularHours: '36:45',
		weekdaysOT: '4:30',
		sundayOT: '3:00',
		timeShiftHours: '2:30',
		hourlySalary: '20 ZMW',
		totalHours: '46:45',
	},
];

export const totalDeductionData = [
	{
		id: '1',
		optionalEmployeeId: 'CFL001',
		employee: 'John Doe',
		paye: '12.00 ZMW',
		nhimaEmployee: '200.00 ZMW',
		nhimaEmployer: '150.00 ZMW',
		napsaEmployee: '100.00 ZMW',
		napsaEmployer: '75.00 ZMW',
		midMonth: '500.00 ZMW',
		cashLoans: '300.00 ZMW',
		houseLoan: '400.00 ZMW',
		medicalFund: '50.00 ZMW',
		unionContribution: '60.00 ZMW',
		mealsDeduction: '25.00 ZMW',
		total: '2960.00 ZMW',
	},
	{
		id: '2',
		optionalEmployeeId: 'CFL002',
		employee: 'Jane Smith',
		paye: '11.00 ZMW',
		nhimaEmployee: '180.00 ZMW',
		nhimaEmployer: '140.00 ZMW',
		napsaEmployee: '90.00 ZMW',
		napsaEmployer: '70.00 ZMW',
		midMonth: '450.00 ZMW',
		cashLoans: '350.00 ZMW',
		houseLoan: '380.00 ZMW',
		medicalFund: '60.00 ZMW',
		unionContribution: '50.00 ZMW',
		mealsDeduction: '30.00 ZMW',
		total: '2900.00 ZMW',
	},
	{
		id: '3',
		optionalEmployeeId: 'CFL003',
		employee: 'Michael Brown',
		paye: '13.00 ZMW',
		nhimaEmployee: '210.00 ZMW',
		nhimaEmployer: '160.00 ZMW',
		napsaEmployee: '110.00 ZMW',
		napsaEmployer: '85.00 ZMW',
		midMonth: '550.00 ZMW',
		cashLoans: '310.00 ZMW',
		houseLoan: '390.00 ZMW',
		medicalFund: '55.00 ZMW',
		unionContribution: '65.00 ZMW',
		mealsDeduction: '20.00 ZMW',
		total: '3145.00 ZMW',
	},
];

export const totalDeductionRow = (showEmployer: boolean) => {
	return {
		id: 'total',
		employee: 'Total',
		paye: '36.00 ZMW',
		nhimaEmployee: '590.00 ZMW',
		nhimaEmployer: showEmployer ? '480.00 ZMW' : '',
		napsaEmployee: '300.00 ZMW',
		napsaEmployer: showEmployer ? '230.00 ZMW' : '',
		midMonth: '1500.00 ZMW',
		cashLoans: '960.00 ZMW',
		houseLoan: '1170.00 ZMW',
		medicalFund: '165.00 ZMW',
		unionContribution: '175.00 ZMW',
		mealsDeduction: '85.00 ZMW',
		total: '3980.00 ZMW',
	};
};

export const totalEarningData = [
	{
		id: 'CFL001',
		employee: 'John Doe',
		housingAllowance: '500.00 ZMW',
		transportAllowance: '200.00 ZMW',
		gratuityPay: '300.00 ZMW',
		shiftDifferentialPay: '150.00 ZMW',
		houseRent: '1000.00 ZMW',
		weekDaysOvertime: '120.00 ZMW',
		sundayOvertime: '200.00 ZMW',
		lunchAllowance: '80.00 ZMW',
		seventhDayOvertime: '150.00 ZMW',
		leavePay: '500.00 ZMW',
		total: '3200.00 ZMW',
	},
	{
		id: 'CFL002',
		employee: 'Jane Smith',
		housingAllowance: '400.00 ZMW',
		transportAllowance: '150.00 ZMW',
		gratuityPay: '250.00 ZMW',
		shiftDifferentialPay: '120.00 ZMW',
		houseRent: '900.00 ZMW',
		weekDaysOvertime: '100.00 ZMW',
		sundayOvertime: '180.00 ZMW',
		lunchAllowance: '70.00 ZMW',
		seventhDayOvertime: '140.00 ZMW',
		leavePay: '450.00 ZMW',
		total: '2830.00 ZMW',
	},
	{
		id: 'CFL003',
		employee: 'Robert Johnson',
		housingAllowance: '600.00 ZMW',
		transportAllowance: '220.00 ZMW',
		gratuityPay: '320.00 ZMW',
		shiftDifferentialPay: '160.00 ZMW',
		houseRent: '1100.00 ZMW',
		weekDaysOvertime: '140.00 ZMW',
		sundayOvertime: '210.00 ZMW',
		lunchAllowance: '85.00 ZMW',
		seventhDayOvertime: '170.00 ZMW',
		leavePay: '550.00 ZMW',
		total: '3535.00 ZMW',
	},
];
export const totalEarningRow = () => {
	return {
		id: 'total',
		employee: 'Total',
		housingAllowance: '1500.00 ZMW',
		transportAllowance: '570.00 ZMW',
		gratuityPay: '870.00 ZMW',
		shiftDifferentialPay: '430.00 ZMW',
		houseRent: '3000.00 ZMW',
		weekDaysOvertime: '360.00 ZMW',
		sundayOvertime: '590.00 ZMW',
		lunchAllowance: '235.00 ZMW',
		seventhDayOvertime: '460.00 ZMW',
		leavePay: '1500.00 ZMW',
		total: '9560.00 ZMW',
	};
};

export const totalPayData = [
	{
		id: '1',
		firstName: 'John',
		lastName: 'Doe',
		optionalEmployeeId: 'CFL001',
		totalEarnings: 1000,
		totalDeductions: 500,
		netPay: 500,
	},
	{
		id: '2',
		firstName: 'Jane',
		lastName: 'Smith',
		optionalEmployeeId: 'CFL002',
		totalEarnings: 2500,
		totalDeductions: 1000,
		netPay: 1500,
	},
	{
		id: '3',
		firstName: 'Alice',
		lastName: 'Johnson',
		optionalEmployeeId: 'CFL003',
		totalEarnings: 3100,
		totalDeductions: 1000,
		netPay: 2100,
	},
	{
		id: '4',
		firstName: 'Bob',
		lastName: 'Brown',
		optionalEmployeeId: 'CFL004',
		totalEarnings: 1000,
		totalDeductions: 300,
		netPay: 700,
	},
	{
		id: '5',
		firstName: 'Bob',
		lastName: 'Smith',
		optionalEmployeeId: 'CFL005',
		totalEarnings: 1200,
		totalDeductions: 400,
		netPay: 700,
	},
];

export const paySlipData = [
	{
		id: '1',
		firstName: 'John',
		lastName: 'Doe',
		optionalEmployeeId: 'E001',
		paymentSlips: 'Pay_slip_E001.pdf',
	},
	{
		id: '2',
		firstName: 'Jane',
		lastName: 'Smith',
		optionalEmployeeId: 'E002',
		paymentSlips: 'Pay_slip_E002.pdf',
	},
	{
		id: '3',
		firstName: 'Alice',
		lastName: 'Johnson',
		optionalEmployeeId: 'E003',
		paymentSlips: 'Pay_slip_E003.pdf',
	},
	{
		id: '4',
		firstName: 'Bob',
		lastName: 'Brown',
		optionalEmployeeId: 'E004',
		paymentSlips: 'Pay_slip_E004.pdf',
	},
	{
		id: '5',
		firstName: 'Alice',
		lastName: 'Johnson',
		optionalEmployeeId: 'E005',
		paymentSlips: 'Pay_slip_E005.pdf',
	},
	{
		id: '6',
		firstName: 'Bob',
		lastName: 'Brown',
		optionalEmployeeId: 'E006',
		paymentSlips: 'Pay_slip_E006.pdf',
	},
];
