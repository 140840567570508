import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toastText } from 'utils/utils';
import WorkingHoursTable from './Table';
import styles from './index.module.scss';
import { workHoursApi } from 'Api/workHours';
import GlobalHeader from '../GlobalHeader';

type WorkingHoursProps = {
	isLoading: boolean;
	workHoursData: any;
	tableChangeHandler: (filter: any, sorter: any) => void;
};
const WorkHourComponent = (props: WorkingHoursProps) => {
	const { isLoading, workHoursData, tableChangeHandler } = props;
	const navigate = useNavigate();

	return (
		<div className={styles['work-hours__table']}>
			<GlobalHeader />
			<WorkingHoursTable
				workHoursData={workHoursData}
				isLoading={isLoading}
				tableChangeHandler={tableChangeHandler}
			/>
		</div>
	);
};

export default WorkHourComponent;
